import authEn from 'i18n/en/auth/auth.json'
import authUa from 'i18n/uk/auth/auth.json'
import confirmEmailEn from 'i18n/en/auth/confirmEmail.json'
import confirmEmailUa from 'i18n/uk/auth/confirmEmail.json'
import welcomePageEn from 'i18n/en/welcome.json'
import welcomePageUa from 'i18n/uk/welcome.json'
import billingEn from 'i18n/en/billing.json'
import billingUa from 'i18n/uk/billing.json'
import accountSettingsEn from 'i18n/en/accountSettings.json'
import accountSettingsUa from 'i18n/uk/accountSettings.json'
import buttonsEn from 'i18n/en/buttons.json'
import buttonsUa from 'i18n/uk/buttons.json'
import mainComponentsEn from 'i18n/en/components/main.json'
import mainComponentsUa from 'i18n/uk/components/main.json'
import popupsEn from 'i18n/en/popups.json'
import popupsUa from 'i18n/uk/popups.json'
import toastEn from 'i18n/en/toast.json'
import toastUa from 'i18n/uk/toast.json'
import aiEn from 'i18n/en/ai.json'
import aiUa from 'i18n/uk/ai.json'
import buildEn from 'i18n/en/build/build.json'
import buildUa from 'i18n/uk/build/build.json'
import emulatorEn from 'i18n/en/emulator.json'
import emulatorUa from 'i18n/uk/emulator.json'
import appListEn from 'i18n/en/app-list/list.json'
import appListUa from 'i18n/uk/app-list/list.json'
import appNameEn from 'i18n/en/app-name/appName.json'
import appNameUa from 'i18n/uk/app-name/appName.json'
import navEn from 'i18n/en/navigation.json'
import navUa from 'i18n/uk/navigation.json'
import menuEn from 'i18n/en/menu.json'
import menuUa from 'i18n/uk/menu.json'

import pickturePickerEn from 'i18n/en/components/pickturePicker.json'
import pickturePickerUa from 'i18n/uk/components/pickturePicker.json'
import notificationsEn from 'i18n/en/notifications.json'
import notificationsUa from 'i18n/uk/notifications.json'

import tutorialsMainEn from 'i18n/en/tutorials/main.json'
import tutorialsMainUa from 'i18n/uk/tutorials/main.json'
import testTutorialsEn from 'i18n/en/tutorials/testing.json'
import testTutorialsUa from 'i18n/uk/tutorials/testing.json'
import settingsTutorialsEn from 'i18n/en/tutorials/settings.json'
import settingsTutorialsUa from 'i18n/uk/tutorials/settings.json'
import publishingTutorialsEn from 'i18n/en/tutorials/publishing.json'
import publishingTutorialsUa from 'i18n/uk/tutorials/publishing.json'
import managingAppsEn from 'i18n/en/tutorials/managingApps.json'
import managingAppsUa from 'i18n/uk/tutorials/managingApps.json'
import featuresTutorialEn from 'i18n/en/tutorials/features.json'
import featuresTutorialUa from 'i18n/uk/tutorials/features.json'
import designTutorialEn from 'i18n/en/tutorials/design.json'
import designTutorialUa from 'i18n/uk/tutorials/design.json'
import billingTutorialEn from 'i18n/en/tutorials/billing.json'
import billingTutorialUa from 'i18n/uk/tutorials/billing.json'
import appNameTutorialEn from 'i18n/en/tutorials/appName.json'
import appNameTutorialUa from 'i18n/uk/tutorials/appName.json'

import generalDesignEn from 'i18n/en/design/generalDesign.json'
import generalDesignUa from 'i18n/uk/design/generalDesign.json'
import ownDesignEn from 'i18n/en/design/ownDesign.json'
import ownDesignUa from 'i18n/uk/design/ownDesign.json'
import welcomeScreenEn from 'i18n/en/design/welcome.json'
import welcomeScreenUa from 'i18n/uk/design/welcome.json'
import iconEn from 'i18n/en/design/icon.json'
import iconUa from 'i18n/uk/design/icon.json'

import aboutEn from 'i18n/en/features/about.json'
import aboutUa from 'i18n/uk/features/about.json'
import blogEn from 'i18n/en/features/blog.json'
import blogUa from 'i18n/uk/features/blog.json'
import paymentLinkEn from 'i18n/en/features/paymentLink.json'
import paymentLinkUa from 'i18n/uk/features/paymentLink.json'
import premiumEn from 'i18n/en/features/premium.json'
import premiumUa from 'i18n/uk/features/premium.json'
import newsEn from 'i18n/en/features/news.json'
import newsUa from 'i18n/uk/features/news.json'
import servicesEn from 'i18n/en/features/services.json'
import servicesUa from 'i18n/uk/features/services.json'
import employeesEn from 'i18n/en/features/employees.json'
import employeesUa from 'i18n/uk/features/employees.json'
import bookingEn from 'i18n/en/features/booking.json'
import bookingUa from 'i18n/uk/features/booking.json'
import calendarEn from 'i18n/en/features/calendar.json'
import calendarUa from 'i18n/uk/features/calendar.json'
import chatEn from 'i18n/en/features/chat.json'
import chatUa from 'i18n/uk/features/chat.json'
import cloudEn from 'i18n/en/features/cloud.json'
import cloudUa from 'i18n/uk/features/cloud.json'
import ereaderEn from 'i18n/en/features/ereader.json'
import ereaderUa from 'i18n/uk/features/ereader.json'
import galleryEn from 'i18n/en/features/gallery.json'
import galleryUa from 'i18n/uk/features/gallery.json'
import mapEn from 'i18n/en/features/map.json'
import mapUa from 'i18n/uk/features/map.json'
import smmEn from 'i18n/en/features/smm.json'
import smmUa from 'i18n/uk/features/smm.json'
import videoEn from 'i18n/en/features/video.json'
import videoUa from 'i18n/uk/features/video.json'
import conferenceEn from 'i18n/en/features/conference.json'
import conferenceUa from 'i18n/uk/features/conference.json'
import liveChatEn from 'i18n/en/features/liveChat.json'
import liveChatUa from 'i18n/uk/features/liveChat.json'
import todoEn from 'i18n/en/features/todo.json'
import todoUa from 'i18n/uk/features/todo.json'
import paymentsEn from 'i18n/en/features/payments.json'
import paymentsUa from 'i18n/uk/features/payments.json'
import surveyEn from 'i18n/en/features/survey.json'
import surveyUa from 'i18n/uk/features/survey.json'

import generalSettingsEn from 'i18n/en/settings/generalSettings.json'
import generalSettingsUa from 'i18n/uk/settings/generalSettings.json'
import featureActivEn from 'i18n/en/settings/featureActivation.json'
import featureActivUa from 'i18n/uk/settings/featureActivation.json'
import userRolesEn from 'i18n/en/settings/userRoles.json'
import userRolesUa from 'i18n/uk/settings/userRoles.json'

import languageSwitcherEn from 'i18n/en/components/languageSwitcher.json'
import languageSwitcherUa from 'i18n/uk/components/languageSwitcher.json'
import placingServiceEn from 'i18n/en/components/placingService.json'
import placingServiceUa from 'i18n/uk/components/placingService.json'

export const resources = {
    en: {
        menu: menuEn,
        pickturePicker: pickturePickerEn,
        tutorial: tutorialsMainEn,
        testTutorials: testTutorialsEn,
        settingsTutorials: settingsTutorialsEn,
        publishingTutorials: publishingTutorialsEn,
        managingApps: managingAppsEn,
        featureTutorials: featuresTutorialEn,
        designTutorials: designTutorialEn,
        billingTutorial: billingTutorialEn,
        appNameTutorial: appNameTutorialEn,
        mainComponents: mainComponentsEn,
        auth: authEn,
        confirmEmail: confirmEmailEn,
        welcomePage: welcomePageEn,
        billing: billingEn,
        accountSettings: accountSettingsEn,
        buttons: buttonsEn,
        appName: appNameEn,
        generalDesign: generalDesignEn,
        welcomeScreen: welcomeScreenEn,
        ownDesign: ownDesignEn,
        icon: iconEn,
        popups: popupsEn,
        blog: blogEn,
        paymentLink: paymentLinkEn,
        premium: premiumEn,
        news: newsEn,
        about: aboutEn,
        services: servicesEn,
        employees: employeesEn,
        calendar: calendarEn,
        chat: chatEn,
        cloud: cloudEn,
        booking: bookingEn,
        ereader: ereaderEn,
        gallery: galleryEn,
        map: mapEn,
        smm: smmEn,
        video: videoEn,
        conference: conferenceEn,
        liveChat: liveChatEn,
        todo: todoEn,
        generalSettings: generalSettingsEn,
        featureActiv: featureActivEn,
        userRoles: userRolesEn,
        build: buildEn,
        toast: toastEn,
        appList: appListEn,
        emulator: emulatorEn,
        ai: aiEn,
        nav: navEn,
        notifications: notificationsEn,
        payments: paymentsEn,
        languageSwitcher: languageSwitcherEn,
        placing: placingServiceEn,
        survey: surveyEn,
    },
    uk: {
        menu: menuUa,
        pickturePicker: pickturePickerUa,
        tutorial: tutorialsMainUa,
        testTutorials: testTutorialsUa,
        settingsTutorials: settingsTutorialsUa,
        publishingTutorials: publishingTutorialsUa,
        managingApps: managingAppsUa,
        featureTutorials: featuresTutorialUa,
        designTutorials: designTutorialUa,
        billingTutorial: billingTutorialUa,
        appNameTutorial: appNameTutorialUa,
        mainComponents: mainComponentsUa,
        auth: authUa,
        confirmEmail: confirmEmailUa,
        welcomePage: welcomePageUa,
        billing: billingUa,
        accountSettings: accountSettingsUa,
        buttons: buttonsUa,
        appName: appNameUa,
        generalDesign: generalDesignUa,
        welcomeScreen: welcomeScreenUa,
        ownDesign: ownDesignUa,
        icon: iconUa,
        popups: popupsUa,
        blog: blogUa,
        paymentLink: paymentLinkUa,
        premium: premiumUa,
        news: newsUa,
        about: aboutUa,
        services: servicesUa,
        employees: employeesUa,
        calendar: calendarUa,
        chat: chatUa,
        cloud: cloudUa,
        booking: bookingUa,
        ereader: ereaderUa,
        gallery: galleryUa,
        map: mapUa,
        smm: smmUa,
        video: videoUa,
        conference: conferenceUa,
        liveChat: liveChatUa,
        todo: todoUa,
        generalSettings: generalSettingsUa,
        featureActiv: featureActivUa,
        userRoles: userRolesUa,
        build: buildUa,
        toast: toastUa,
        appList: appListUa,
        emulator: emulatorUa,
        ai: aiUa,
        nav: navUa,
        notifications: notificationsUa,
        payments: paymentsUa,
        languageSwitcher: languageSwitcherUa,
        placing: placingServiceUa,
        survey: surveyUa,
    },
} as const
