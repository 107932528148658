import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { SURVEY_URLS } from 'config/urls'

import { ITestAnalytics } from '../store/types'

export const getTestAnalyticsById = ({
    applicationId,
    surveyId,
    surveyCompletionId,
}: {
    applicationId?: string
    surveyId?: string
    surveyCompletionId?: string
}): Promise<AxiosResponse<ITestAnalytics>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${applicationId}${SURVEY_URLS.main}/${surveyId}/surveyCompletions/${surveyCompletionId}`,
    )
