/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'

import { RootState } from 'redux/reducers/rootReducer'
import { getAppIdCookie } from 'utils/token'
import { TError } from 'config/types'

// eslint-disable-next-line max-len
import { ReactComponent as InstagramHollow } from 'assets/images/social-media-icons/smm/HInstagram.svg'
// eslint-disable-next-line max-len
import { ReactComponent as FacebookHollow } from 'assets/images/social-media-icons/smm/HFacebook.svg'
// eslint-disable-next-line max-len
import { ReactComponent as LinkedInHollow } from 'assets/images/social-media-icons/smm/LinkedInHollow.svg'
// eslint-disable-next-line max-len
import { ReactComponent as TwitterHollow } from 'assets/images/social-media-icons/smm/TwitterHollow.svg'
// eslint-disable-next-line max-len
import { ReactComponent as YoutubeHollow } from 'assets/images/social-media-icons/smm/YoutubeHollow.svg'
import { ReactComponent as Price } from 'assets/images/new-loader/Price.svg'
import { ReactComponent as Clock } from 'assets/images/new-loader/Clock.svg'
import { ReactComponent as Star } from 'assets/images/icons/Star.svg'

import './EmployeeItem.scss'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

// eslint-disable-next-line max-len
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'
import { fetchEmployeeAction } from 'containers/dashboard/features/employees/store/actions'

type PageParams = {
    id: string
}

const EmployeeItem = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast', 'employees'])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const appId = getAppIdCookie()

    const { id } = useParams<PageParams>()
    const { employee } = useSelector((state: RootState) => state.employees)

    const renderIcon = (type: string) => {
        switch (type) {
            case 'Facebook':
                return <FacebookHollow />
            case 'Instagram':
                return <InstagramHollow />
            case 'LinkedIn':
                return <LinkedInHollow />
            case 'Youtube':
                return <YoutubeHollow />
            case 'Twitter':
                return <TwitterHollow />
            default:
                return ''
        }
    }

    useEffect(() => {
        if (!appId) return
        if (!id) return
        setIsLoading(true)
        dispatch(
            fetchEmployeeAction({
                employeeId: id,
                appId,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                    setIsLoading(false)
                },
            }),
        )
    }, [appId, dispatch, id, t, i18n.language])

    const displayCurrency = (currency: string) => {
        switch (currency) {
            case 'UAH':
                return <>&#x20b4;</>
            case 'USD':
                return <>&#x24;</>
            case 'EUR':
                return <>&#8364;</>
            case 'IRP':
                return <>&#8377;</>
            default:
                return ''
        }
    }

    return (
        <>
            {!isLoading ? (
                employee ? (
                    <div className='m-employee-item'>
                        <CustomImage src={employee.imageUrl} />
                        <div className='m-employee-item__info new-loader--surface'>
                            {/* eslint-disable-next-line max-len */}
                            <div className='m-employee-item__title new-loader--text'>
                                {employee.firstName}
                            </div>
                            <div className='m-employee-item__date new-loader--text'>
                                {employee.role}
                            </div>
                            <div className='m-employee-item__measure'>
                                <div className='m-employee-item__exp'>
                                    <span>
                                        {t('employees:years', {
                                            n: employee.experience,
                                        })}
                                    </span>
                                    {t('employees:exp')}
                                </div>
                                {employee.rating ? (
                                    <div>{employee.experience}</div>
                                ) : (
                                    <div className='no-rating'>
                                        <Star className='new-loader--icon' />
                                        {t('employees:noRating')}
                                    </div>
                                )}
                            </div>
                            <div className='m-employee-item__blocks new-loader--text'>
                                <div className='m-employee-item__block'>
                                    <div className='m-employee-item__heading'>
                                        {t('employees:about')}
                                    </div>
                                    <div className='m-employee-item__about'>
                                        {parse(employee.description)}
                                    </div>
                                </div>
                                {employee.socialMedia.length > 0 ? (
                                    <div className='m-employee-item__block'>
                                        <div className='m-employee-item__heading'>
                                            {t('employees:follow')}
                                        </div>
                                        {/* eslint-disable-next-line max-len */}
                                        <div className='m-employee-item__socials new-loader--primary-color'>
                                            {employee.socialMedia.map(
                                                (sm: any) => (
                                                    <div className='m-employee-item__social'>
                                                        {renderIcon(sm.type)}
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                                {employee.service ? (
                                    <div className='m-employee-item__block'>
                                        <div className='m-employee-item__heading'>
                                            {t('employees:service')}
                                        </div>
                                        <div className='m-employee-item__service'>
                                            <CustomImage
                                                src={employee.service.imageUrl}
                                                className='m-employee-item__s-image'
                                            />
                                            <div className='m-employee-item__s-info'>
                                                <div className='m-employee-item__s-title new-loader--text'>
                                                    {employee.service.name}
                                                </div>
                                                <div className='m-employee-item__s-desc new-loader--text'>
                                                    {employee.service.name}
                                                </div>
                                                <div className='m-employee-item__s-values'>
                                                    <div className='m-employee-item__s-tag new-loader--primary-color'>
                                                        <Price />
                                                        {displayCurrency(
                                                            employee.service
                                                                .currency,
                                                        )}
                                                        {employee.service.price}

                                                        <div className='m-employee-item__s-background new-loader--primary-background' />
                                                    </div>

                                                    {employee.service
                                                        .duration ? (
                                                        <div className='m-employee-item__s-tag new-loader--primary-color'>
                                                            <Clock />
                                                            {`${Math.floor(
                                                                employee.service
                                                                    .duration /
                                                                    60,
                                                            )}:${
                                                                employee.service
                                                                    .duration %
                                                                60
                                                            }`}
                                                            <div className='m-employee-item__s-background new-loader--primary-background' />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : (
                    <EmulatorEmpty />
                )
            ) : (
                <Preloader />
            )}
        </>
    )
}

export default EmployeeItem
