import { bookingTypes } from './actionTypes'
import { BookingActions, BookingState } from './types'

const initialState: BookingState = {
    bookings: undefined,
    booking: undefined,
    managementSlots: undefined,
    selectedSchedule: undefined,
    relativeBooking: undefined,
    billingUrl: undefined,
}

export default (state = initialState, action: BookingActions): BookingState => {
    switch (action.type) {
        case bookingTypes.SET_BOOKINGS:
            return {
                ...state,
                bookings: action.payload.bookings,
            }
        case bookingTypes.SET_MANAGEMENT:
            return {
                ...state,
                managementSlots: action.payload.managementSlots,
            }
        case bookingTypes.SET_BOOKING:
            return {
                ...state,
                booking: action.payload.booking,
            }

        case bookingTypes.SET_SCHEDULE:
            return {
                ...state,
                selectedSchedule: action.payload.selectedSchedule,
            }
        case bookingTypes.SET_RELATIVE_BOOKING:
            return {
                ...state,
                relativeBooking: action.payload.relativeBooking,
            }
        case bookingTypes.SET_BILLING_REQUEST:
            return {
                ...state,
                billingUrl: action.payload.billingUrl,
            }
        default:
            return {
                ...state,
            }
    }
}
