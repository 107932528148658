import React, { useState, useEffect } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

import { PATH_NEW_LOADER } from 'config/paths'

// import PhoneStatus from 'assets/images/new-loader/PhoneStatus'
import TodoArchive from 'assets/images/new-loader/todo/TodoArchive'
import { useTranslation } from 'react-i18next'

import ProfileIcon from 'assets/images/new-loader/ProfileIcon'
import BurgerMenu from '../burger-menu/BurgerMenu'
import Language from './components/Language'

import { data } from './data'

import './EmulatorHeader.scss'

type IHeader = {
    profileEnabled?: boolean
}

const Header = ({ profileEnabled }: IHeader): React.ReactElement => {
    useEffect(
        () => () => {
            document.body.style.overflowY = 'scroll'
        },
        [],
    )

    const { t } = useTranslation('emulator')

    const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
    const { push } = useHistory()
    const { pathname } = useLocation()

    const isHeaderVisible = data.find(item =>
        pathname.includes(item.url),
    )?.isHeaderVisible
    // const isStatusVisible = data.find(item =>
    //     pathname.includes(item.url),
    // )?.isStatusVisible
    const isArchiveVisible = data.find(item =>
        pathname.includes(item.url),
    )?.isArchiveVisible

    const todoRedirect = () => {
        if (pathname.includes(PATH_NEW_LOADER.pages.todo.root))
            push(PATH_NEW_LOADER.pages.todo.archive)
        else push(PATH_NEW_LOADER.pages.todo.root)
    }

    const title = data.find(item => pathname.includes(item.url))?.title

    return (
        <>
            {/* {isStatusVisible && (
                <Stack position='absolute'>
                    <PhoneStatus />
                </Stack>
            )} */}
            {isHeaderVisible && (
                <div
                    className={`emulator-header ${
                        isHeaderVisible ? 'emulator-header--visible' : ''
                    } new-loader--navbar new-loader--text`}
                >
                    <button
                        type='button'
                        className='emulator-header__profile'
                        onClick={() => {
                            setMenuOpen(!isMenuOpen)
                            document.body.style.overflowY = 'hidden'
                        }}
                    >
                        <MenuIcon className='new-loader--text' />
                    </button>
                    <div className='emulator-header__title'>
                        {title && t(title)}
                    </div>

                    <div className='emulator-header__actions'>
                        <Language />
                        {isArchiveVisible && (
                            <button
                                type='button'
                                // eslint-disable-next-line max-len
                                className='emulator-header__todo new-loader--text'
                                onClick={() => todoRedirect()}
                            >
                                <TodoArchive />
                            </button>
                        )}
                        {profileEnabled && (
                            <button
                                type='button'
                                className='emulator-header__profile'
                                onClick={() => console.log('profile')}
                            >
                                <ProfileIcon className='new-loader--text' />
                            </button>
                        )}
                    </div>
                </div>
            )}
            <BurgerMenu isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
        </>
    )
}

Header.defaultProps = {
    profileEnabled: '',
}

export default Header
