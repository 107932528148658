/* eslint-disable max-len */
import React from 'react'

import { useMediaQuery } from '@mui/material'

import AppNameIcon from 'assets/images/constructor/AppNameIcon'
import DesignIcon from 'assets/images/constructor/DesignIcon'
import FeaturesIcon from 'assets/images/constructor/FeaturesIcon'
import SettingsIcon from 'assets/images/constructor/SettingsIcon'
import BuildIcon from 'assets/images/constructor/BuildIcon'
import AnalyticsIcon from 'assets/images/constructor/AnalyticsIcon'
import BillingIcon from 'assets/images/constructor/BillingIcon'
import MyAppsIcon from 'assets/images/constructor/MyAppsIcon'
import ProfileIcon from 'assets/images/constructor/ProfileIcon'
import PublishingIcon from 'assets/images/constructor/PublishingIcon'
import TestingIcon from 'assets/images/constructor/TestingIcon'
import PreviewIcon from 'assets/images/constructor/PreviewIcon'

import GeneralDesignIcon from 'assets/images/constructor/menu/design/GeneralDesignIcon'
import OwnDesignIcon from 'assets/images/constructor/menu/design/OwnDesignIcon'
import WelcomeScreenIcon from 'assets/images/constructor/menu/design/WelcomeScreenIcon'
import IconIcon from 'assets/images/constructor/menu/design/IconIcon'

import BlogIcon from 'assets/images/constructor/menu/features/BlogIcon'
import NewsIcon from 'assets/images/constructor/menu/features/NewsIcon'
import BookingIcon from 'assets/images/constructor/menu/features/BookingIcon'
import VideoIcon from 'assets/images/constructor/menu/features/VideoIcon'
import ConferenceIcon from 'assets/images/constructor/menu/features/ConferenceIcon'
import CalendarIcon from 'assets/images/constructor/menu/features/CalendarIcon'
import GalleryIcon from 'assets/images/constructor/menu/features/GalleryIcon'
import AboutIcon from 'assets/images/constructor/menu/features/AboutIcon'
import EmployeeIcon from 'assets/images/constructor/menu/features/EmployeeIcon'
import MapIcon from 'assets/images/constructor/menu/features/MapIcon'
import EReaderIcon from 'assets/images/constructor/menu/features/EReaderIcon'
import PaymentLinkIcon from 'assets/images/constructor/menu/features/PaymentLink'
import CloudIcon from 'assets/images/constructor/menu/features/CloudIcon'
import SmmIcon from 'assets/images/constructor/menu/features/SmmIcon'
import ChatIcon from 'assets/images/constructor/menu/features/ChatIcon'
import SurveyIcon from 'assets/images/constructor/menu/features/SurveyIcon'
import ServicesIcon from 'assets/images/constructor/menu/features/ServicesIcon'
import TodoIcon from 'assets/images/constructor/menu/features/TodoIcon'
import LiveChatIcon from 'assets/images/constructor/menu/features/LiveChat'
import ForumIcon from 'assets/images/constructor/menu/features/ForumIcon'

import FeatureActivationIcon from 'assets/images/constructor/menu/settings/FeatureActivation'
import FirebaseIcon from 'assets/images/constructor/menu/settings/Firebase'
import UsersIcon from 'assets/images/constructor/menu/settings/User'
import PushNotificationsIcon from 'assets/images/constructor/menu/settings/Push'

import Androidicon from 'assets/images/constructor/menu/build/Android'
import IosIcon from 'assets/images/constructor/menu/build/Ios'

import BillingInfoIcon from 'assets/images/constructor/features/BillingInfoIcon'
import PaymentsIcon from 'assets/images/constructor/menu/features/PaymentsIcon'
import GeneralSettings from 'assets/images/constructor/menu/settings/GeneralSettings'

interface Props {
    iconName: string
    isActive?: boolean
}

// Maybe change it as in another render component
export const IconRender = ({
    iconName,
    isActive,
}: Props): React.ReactElement => {
    const smallScreen = useMediaQuery('(max-width:560px)')
    const mediumScreen = useMediaQuery('(max-width:1200px)')

    switch (iconName) {
        case 'testing':
            return <TestingIcon isActive={isActive} isSmall={smallScreen} />
        case 'publishing':
            return <PublishingIcon isActive={isActive} isSmall={smallScreen} />
        case 'billing':
            return <BillingIcon isActive={isActive} isSmall={smallScreen} />
        case 'my-apps':
            return <MyAppsIcon isActive={isActive} isSmall={smallScreen} />
        case 'profile':
            return <ProfileIcon isActive={isActive} isSmall={smallScreen} />
        case 'app-name':
            return (
                <AppNameIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'design':
            return (
                <DesignIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'features':
            return (
                <FeaturesIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'settings':
            return (
                <SettingsIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'build':
            return (
                <BuildIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'analytics':
            return (
                <AnalyticsIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'loader':
            return (
                <PreviewIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'general-design':
            return (
                <GeneralDesignIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'own-design':
            return (
                <OwnDesignIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'welcome-screen':
            return (
                <WelcomeScreenIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'icon':
            return (
                <IconIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'payment-link':
            return (
                <PaymentLinkIcon
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'blog':
            return <BlogIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'news':
            return <NewsIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'gallery':
            return <GalleryIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'cloud':
            return <CloudIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'calendar':
            return (
                <CalendarIcon isSmall={smallScreen} isMedium={mediumScreen} />
            )
        case 'forum':
            return <ForumIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'todo':
            return <TodoIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'chat-links':
            return <ChatIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'chat-premium':
            return (
                <LiveChatIcon isSmall={smallScreen} isMedium={mediumScreen} />
            )
        case 'smm':
            return <SmmIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'about':
            return <AboutIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'employee':
            return (
                <EmployeeIcon isSmall={smallScreen} isMedium={mediumScreen} />
            )
        case 'booking':
            return <BookingIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'map':
            return <MapIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'services':
            return (
                <ServicesIcon isSmall={smallScreen} isMedium={mediumScreen} />
            )
        case 'survey':
            return <SurveyIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'video':
            return <VideoIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'conference':
            return (
                <ConferenceIcon isSmall={smallScreen} isMedium={mediumScreen} />
            )
        case 'e-reader':
            return <EReaderIcon isSmall={smallScreen} isMedium={mediumScreen} />
        case 'billing-info':
            return (
                <BillingInfoIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'payments':
            return (
                <PaymentsIcon isSmall={smallScreen} isMedium={mediumScreen} />
            )
        case 'firebase':
            return (
                <FirebaseIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'general-settings':
            return (
                <GeneralSettings
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'feature-activation':
            return (
                <FeatureActivationIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'user-roles':
            return (
                <UsersIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'push':
            return (
                <PushNotificationsIcon
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'android':
            return (
                <Androidicon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        case 'ios':
            return (
                <IosIcon
                    isActive={isActive}
                    isSmall={smallScreen}
                    isMedium={mediumScreen}
                />
            )
        default:
            return <svg width='60' height='54' />
    }
}
