import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { IPagination } from 'config/types'
import { sagaErrorHandle } from 'utils/errHandle'
import { bookingTypes } from './actionTypes'
import {
    getBookings,
    getBookingById,
    getBookingManagement,
    createBooking,
    editBooking,
    deleteBooking,
    getBookingBill,
} from '../service/booking'

import {
    IBooking,
    IManagement,
    FetchBookingsRequest,
    FetchBookingByIdRequest,
    FetchManagementRequest,
    CreateBookingRequest,
    EditBookingRequest,
    DeleteBookingRequest,
    GetBillingRequest,
} from './types'

function* fetchBookingssSaga(action: FetchBookingsRequest) {
    try {
        const response: AxiosResponse<IPagination<IBooking>> = yield call(
            getBookings,
            action.payload,
        )
        if (response.data) {
            const updatedBookings = response.data.items.map(bookingItem => ({
                ...bookingItem,
                imageUrl: bookingItem.location?.imageUrl,
                name: bookingItem.location?.name,
                address: bookingItem.location?.address,
            }))
            yield put({
                type: bookingTypes.SET_BOOKINGS,
                payload: { bookings: updatedBookings },
            })
            action.payload.callback('success', response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchBookingByIdSaga(action: FetchBookingByIdRequest) {
    try {
        const response: AxiosResponse<IBooking> = yield call(
            getBookingById,
            action.payload,
        )
        if (response.data) {
            if (action.payload.isEdit) {
                const oppositeLang =
                    response.data.language === 'en' ? 'uk' : 'en'
                let relativeBooking: IBooking = {
                    id: '2',
                    name: '',
                    address: '',
                    imageUrl: '',
                    schedule: [
                        {
                            employeeId: undefined,
                            timeSlots: [
                                {
                                    dayOfWeek: 'Monday',
                                    startTime: '09:00:00',
                                    endTime: '18:00:00',
                                    isRepeatable: false,
                                },
                            ],
                            generatedTimeSlots: undefined,
                        },
                    ],
                    language: oppositeLang,
                }

                if (response.data.relativeId) {
                    const props = {
                        appId: action.payload.appId,
                        bookingId: response.data.relativeId,
                    }

                    const relativeResp: AxiosResponse<IBooking> = yield call(
                        getBookingById,
                        props,
                    )

                    if (relativeResp.data) {
                        relativeBooking = relativeResp.data
                    }
                }

                yield put({
                    type: bookingTypes.SET_RELATIVE_BOOKING,
                    payload: { relativeBooking },
                })
            }
            yield put({
                type: bookingTypes.SET_BOOKING,
                payload: { booking: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchManagementSaga(action: FetchManagementRequest) {
    try {
        const response: AxiosResponse<IManagement> = yield call(
            getBookingManagement,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: bookingTypes.SET_MANAGEMENT,
                payload: { managementSlots: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createBookingssSaga(action: CreateBookingRequest) {
    try {
        const response: AxiosResponse<IBooking> = yield call(
            createBooking,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editBookingSaga(action: EditBookingRequest) {
    try {
        const response: AxiosResponse<IBooking> = yield call(
            editBooking,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteBookingSaga(action: DeleteBookingRequest) {
    try {
        const response: AxiosResponse<IBooking> = yield call(
            deleteBooking,
            action.payload,
        )
        if (response.status === 200) {
            const { bookings } = yield select(state => state.booking)
            const newBookings = bookings.filter(
                (item: IBooking) => item.id !== action.payload.bookingId,
            )

            yield put({
                type: bookingTypes.SET_BOOKINGS,
                payload: {
                    bookings: newBookings,
                },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* getBillingSaga(action: GetBillingRequest) {
    try {
        const response: AxiosResponse<string> = yield call(
            getBookingBill,
            action.payload,
        )
        if (response.status === 200) {
            yield put({
                type: bookingTypes.SET_BILLING_REQUEST,
                payload: {
                    billingUrl: response.data,
                },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* employeeSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(bookingTypes.FETCH_BOOKING_REQUEST, fetchBookingssSaga),
    ])
    yield all([
        takeLatest(bookingTypes.FETCH_MANAGEMENT_REQUEST, fetchManagementSaga),
    ])
    yield all([
        takeLatest(bookingTypes.FETCH_BOOKING_BY_ID, fetchBookingByIdSaga),
    ])
    yield all([
        takeLatest(bookingTypes.CREATE_BOOKING_REQUEST, createBookingssSaga),
    ])
    yield all([takeLatest(bookingTypes.EDIT_BOOKING_REQUEST, editBookingSaga)])
    yield all([
        takeLatest(bookingTypes.DELETE_BOOKING_REQUEST, deleteBookingSaga),
    ])
    yield all([takeLatest(bookingTypes.GET_BILLING_REQUEST, getBillingSaga)])
}

export default employeeSaga
