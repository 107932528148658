import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { IPagination } from 'config/types'
import { employeeTypes } from './actionTypes'
import {
    getEmployees,
    getEmployeeById,
    createEmployees,
    editEmployee,
    deleteEmployee,
} from '../service/employee'

import {
    IEmployee,
    FetchEmployeesRequest,
    FetchEmployeeByIdRequest,
    CreateEmployeesRequest,
    EditEmployeeRequest,
    DeleteEmployeeRequest,
} from './types'

function* fetchEmployeesSaga(action: FetchEmployeesRequest) {
    try {
        const response: AxiosResponse<IPagination<IEmployee>> = yield call(
            getEmployees,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: employeeTypes.SET_EMPLOYEES,
                payload: { employees: response.data.items },
            })
            action.payload.callback('success', response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchEmployeeByIdSaga(action: FetchEmployeeByIdRequest) {
    try {
        const response: AxiosResponse<IEmployee> = yield call(
            getEmployeeById,
            action.payload,
        )
        if (response.data) {
            if (action.payload.isEdit) {
                const oppositeLang =
                    response.data.language === 'en' ? 'uk' : 'en'
                let relativeEmployee: IEmployee = {
                    id: '2',
                    firstName: '',
                    lastName: '',
                    description: '',
                    imageUrl: '',
                    experience: '',
                    role: '',
                    socialMedia: [{ type: 'none', url: '' }],
                    socialUrl: '',
                    serviceId: undefined,
                    isTopSpecialist: false,
                    language: oppositeLang,
                }
                if (response.data.relativeId) {
                    const props = {
                        appId: action.payload.appId,
                        language: oppositeLang,
                        employeeId: response.data.relativeId,
                    }
                    const responseRelative: AxiosResponse<IEmployee> =
                        yield call(getEmployeeById, props)

                    if (responseRelative.data) {
                        relativeEmployee = responseRelative.data
                    }
                }
                yield put({
                    type: employeeTypes.SET_REALTIVE_EMPLOYEE,
                    payload: { relativeEmployee },
                })
            }
            yield put({
                type: employeeTypes.SET_EMPLOYEE,
                payload: { employee: response.data },
            })
            if (action.payload.callback) action.payload.callback('success')
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createEmployeesSaga(action: CreateEmployeesRequest) {
    try {
        const response: AxiosResponse<IEmployee> = yield call(
            createEmployees,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editEmployeeSaga(action: EditEmployeeRequest) {
    try {
        const response: AxiosResponse<IEmployee> = yield call(
            editEmployee,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteEmployeeSaga(action: DeleteEmployeeRequest) {
    try {
        const response: AxiosResponse<IEmployee> = yield call(
            deleteEmployee,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { employees } = yield select(state => state.employees)
            const updatedEmployees = employees.filter(
                (employee: IEmployee) =>
                    employee.id !== action.payload.employeeId,
            )

            yield put({
                type: employeeTypes.SET_EMPLOYEES,
                payload: { employees: updatedEmployees },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* employeeSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(employeeTypes.FETCH_EMPLOYEES_REQUEST, fetchEmployeesSaga),
    ])
    yield all([
        takeLatest(employeeTypes.FETCH_EMPLOYEE_BY_ID, fetchEmployeeByIdSaga),
    ])
    yield all([
        takeLatest(employeeTypes.CREATE_EMPLOYEE_REQUEST, createEmployeesSaga),
    ])
    yield all([
        takeLatest(employeeTypes.EDIT_EMPLOYEE_REQUEST, editEmployeeSaga),
    ])
    yield all([
        takeLatest(employeeTypes.DELETE_EMPLOYEE_REQUEST, deleteEmployeeSaga),
    ])
}

export default employeeSaga
