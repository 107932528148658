import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PrivateRoute from 'router/component/PrivateRoute'
import constructorRoutes from 'router/routes/constructor'
import dashboardRoutes from 'router/routes/dashboard'
import authRoutes from 'router/routes/auth'
import placementRoutes from 'router/routes/placement'

import Dashboard from 'layouts/dashboard/Dashboard'
import NewLoaderWrapper from 'layouts/new-loader/NewLoaderWrapper'
import ResetPassword from 'containers/auth/reset-password/ResetPassword'
import ConfirmEmail from 'containers/auth/confirm-email/ConfirmEmail'
import Placement from 'containers/placement/Placement'
import NotFound from './not-found/notFound'
import ScrollToTop from './ScrollToTop'

import 'react-toastify/dist/ReactToastify.css'
import 'styles/index.scss'

import InjectAxiosInterceptors from '../../injectedAxiosInterceptors'

function App(): React.ReactElement {
    const { i18n } = useTranslation()

    useEffect(() => {
        const script = document.createElement('script')

        const bwcWrap = document?.querySelectorAll<HTMLElement>('#bwc-wrap')

        if (bwcWrap) {
            bwcWrap?.forEach(e => {
                if (!e?.classList?.contains('used-chat')) {
                    e?.classList.add('used-chat')
                    e?.classList.add(`${i18n.language}`)
                }
            })

            bwcWrap?.forEach(e => {
                if (e?.classList?.contains(`${i18n.language}`)) {
                    e.style.display = 'none'
                } else {
                    e.style.display = 'block'
                }
            })
        }
        /* eslint-disable max-len */
        script.innerHTML =
            i18n.language === 'en'
                ? "(function(d, w, s) {var widgetHash = 'ZmNOuzlAkKQkWreZxcS2', bch = d.createElement(s); bch.type = 'text/javascript'; bch.async = true; bch.src = '//widgets.binotel.com/chat/widgets/' + widgetHash + '.js'; var sn = d.getElementsByTagName(s)[0]; sn.parentNode.insertBefore(bch, sn);})(document, window, 'script');"
                : "(function(d, w, s) { var widgetHash = 'FkQP5UiY1y8PdOjxbGEb', bch = d.createElement(s); bch.type = 'text/javascript'; bch.async = true; bch.src = '//widgets.binotel.com/chat/widgets/' + widgetHash + '.js'; var sn = d.getElementsByTagName(s)[0]; sn.parentNode.insertBefore(bch, sn);})(document, window, 'script');"

        script.id = 'chat-script'
        script.type = 'text/javascript'
        script.async = true
        script.defer = true

        document.body.appendChild(script)
    }, [i18n.language])

    return (
        <Router
            getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt */
            }}
        >
            <ScrollToTop />
            <React.Suspense fallback={<span />}>
                <InjectAxiosInterceptors />
                <Switch>
                    <Route
                        path='/user/confirm-email/:path?/:path?'
                        component={ConfirmEmail}
                        exact
                    />

                    <Route
                        path='/recovery-password/:path?/:path?'
                        component={ResetPassword}
                        exact
                    />
                    {authRoutes.map(route => (
                        <Route
                            exact
                            path={route.path}
                            component={route.component}
                            key={route.module + route.id}
                        />
                    ))}
                    <Route path='/placement'>
                        <Placement>
                            {placementRoutes.map(route => (
                                <Route
                                    exact
                                    path={route.path}
                                    component={route.component}
                                    key={route.module + route.id}
                                />
                            ))}
                        </Placement>
                    </Route>
                    <Route path='/dashboard'>
                        <Dashboard type='dashboard'>
                            <React.Suspense fallback={<span />}>
                                <Switch>
                                    <Route path='/dashboard/loader'>
                                        <NewLoaderWrapper />
                                    </Route>
                                    {dashboardRoutes.map(route => (
                                        <Route
                                            exact
                                            path={route.path}
                                            component={route.component}
                                            key={route.module + route.id}
                                        />
                                    ))}
                                </Switch>
                            </React.Suspense>
                        </Dashboard>
                    </Route>
                    <Route path='/:path?/:path?/:path?/:path?' exact>
                        <Dashboard type='constructor'>
                            <React.Suspense fallback={<span />}>
                                <Switch>
                                    {constructorRoutes.map(route => {
                                        if (route.protected) {
                                            return (
                                                <PrivateRoute
                                                    exact
                                                    path={route.path}
                                                    component={route.component}
                                                    key={
                                                        route.module + route.id
                                                    }
                                                />
                                            )
                                        }
                                        return (
                                            <Route
                                                exact
                                                path={route.path}
                                                component={route.component}
                                                key={route.module + route.id}
                                            />
                                        )
                                    })}
                                </Switch>
                            </React.Suspense>
                        </Dashboard>
                    </Route>
                    <Route component={NotFound} />
                </Switch>
                <ToastContainer position='bottom-center' theme='dark' />
            </React.Suspense>
        </Router>
    )
}

export default App
