import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    // select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'

import { billingTypes } from './actionTypes'
import {
    getCustomer,
    getSubscriptionTypes,
    getInvoices,
    getPointPacks,
    fetchPaymentLink,
    fetchPlacementTypes,
} from '../service/billing'

import {
    ICustomer,
    FetchCustomerRequest,
    FetchSubscriptionTypes,
    FetchPointsTypes,
    ISubscription,
    ICreditPack,
    FetchPlacingLinkRequest,
    IPlacingType,
    IPlacementLink,
} from './types'

function* fetchCustomerSaga(action: FetchCustomerRequest) {
    try {
        const response: AxiosResponse<ICustomer> = yield call(
            getCustomer,
            action.payload?.lang,
        )
        if (response.data) {
            yield put({
                type: billingTypes.SET_CUSTOMER,
                payload: { customerDetails: response.data },
            })
            if (action.payload?.callback) {
                action.payload.callback(response.data)
            }
        }
    } catch (e) {
        // errors
    }
}

function* fetchSubscriptionsSaga(action: FetchSubscriptionTypes) {
    try {
        const response: AxiosResponse<ISubscription> = yield call(
            getSubscriptionTypes,
            action.payload?.lang,
        )
        if (response.data) {
            yield put({
                type: billingTypes.SET_SUBSCRIPTIONS,
                payload: { subscriptions: response.data },
            })
        }
    } catch (error) {
        // errors
    }
}

function* fetchCreditPacksSaga(action: FetchPointsTypes) {
    try {
        const response: AxiosResponse<ICreditPack[]> = yield call(
            getPointPacks,
            action.payload?.lang,
        )
        if (response.data) {
            yield put({
                type: billingTypes.SET_POINT_PACKS,
                payload: { credits: response.data },
            })
        }
    } catch (error) {
        // errors
    }
}

function* fetchInvoicesSaga() {
    try {
        const response: AxiosResponse<ISubscription> = yield call(getInvoices)
        if (response.data) {
            yield put({
                type: billingTypes.SET_INVOICES,
                payload: { invoices: response.data },
            })
        }
    } catch (error) {
        // errors
    }
}

function* fetchPlacingLinkSaga(action: FetchPlacingLinkRequest) {
    try {
        const response: AxiosResponse<IPlacementLink> = yield call(
            fetchPaymentLink,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success', [], response.data.url)
            }
        }
    } catch (err) {
        sagaErrorHandle(action.payload.callback, err)
    }
}

function* fetchPlacingTypes() {
    try {
        const response: AxiosResponse<IPlacingType[]> = yield call(
            fetchPlacementTypes,
        )
        if (response.data) {
            yield put({
                type: billingTypes.SET_PLACING_TYPES,
                payload: { placingTypes: response.data },
            })
        }
    } catch (error) {
        // console.error(error)
    }
}

function* billingSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(billingTypes.FETCH_CUSTOMER_REQUEST, fetchCustomerSaga),
        takeLatest(billingTypes.FETCH_POINTS_TYPES, fetchCreditPacksSaga),
        takeLatest(billingTypes.FETCH_PLACING_LINK, fetchPlacingLinkSaga),
        takeLatest(
            billingTypes.FETCH_SUBSCRIPTION_TYPES,
            fetchSubscriptionsSaga,
        ),
        takeLatest(billingTypes.FETCH_INVOICES, fetchInvoicesSaga),
        takeLatest(billingTypes.FETCH_PLACING_TYPES, fetchPlacingTypes),
    ])
}

export default billingSaga
