import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { BOOKING_URLS } from 'config/urls'

import {
    IBooking,
    TWorkingDay,
    TTimeSlots,
    IBookingMultiLang,
    IManagement,
} from '../store/types'

export const getBookings = ({
    language,
    appId,
    page,
}: {
    appId: string
    page: string | number
    language: string
}): Promise<AxiosResponse<IBooking[]>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${BOOKING_URLS.booking}/language/${language}?pageNumber=${page}&pageSize=10`,
        // `${appId}${BOOKING_URLS.booking}?pageNumber=${page}&pageSize=10`,
    )

export const getBookingById = ({
    bookingId,
    appId,
}: {
    bookingId: string
    appId: string
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.get(`${appId}${BOOKING_URLS.booking}/${bookingId}`)
// axiosInstance.get(`${appId}${BOOKING_URLS.booking}/${bookingId}`)

export const getBookingManagement = ({
    bookingId,
    appId,
    date,
}: {
    bookingId: string
    appId: string
    date?: string
}): Promise<AxiosResponse<IManagement>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${BOOKING_URLS.booking}/management/${bookingId}${
            date ? `?selectedDate=${date}` : ''
        }`,
    )

export const generateTimeSlots = ({
    appId,
    timeSlots,
}: {
    appId: string
    timeSlots: TWorkingDay[]
}): Promise<AxiosResponse<TTimeSlots[]>> =>
    axiosInstance.post(
        `${appId}${BOOKING_URLS.booking}/time-slots?duration=30`,
        timeSlots,
    )

export const createBooking = ({
    booking,
    appId,
}: {
    booking: IBookingMultiLang
    appId: string
}): Promise<AxiosResponse<IBooking>> =>
    // eslint-disable-next-line max-len
    axiosInstance.post(`${appId}${BOOKING_URLS.booking}`, booking)

export const editBooking = ({
    bookingId,
    booking,
    appId,
}: {
    bookingId: string
    booking: IBooking
    appId: string
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.put(`${appId}${BOOKING_URLS.booking}/${bookingId}`, booking)

export const deleteBooking = ({
    bookingId,
    appId,
}: {
    appId: string
    bookingId: string
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.delete(`${appId}${BOOKING_URLS.booking}/${bookingId}`)

export const updateSlotInfo = ({
    appId,
    bookingId,
    slotId,
    slot,
}: {
    appId: string
    bookingId: string
    slotId: string
    slot: any
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.put(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `${appId}${BOOKING_URLS.booking}/management/${bookingId}/${slotId}`,
        slot,
    )

export const bookSlot = ({
    appId,
    bookingId,
    slotId,
    name,
    email,
    phone,
    date,
}: {
    appId: string
    bookingId: string
    slotId: string
    name: string
    email: string
    phone: string
    date: string
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.post(
        // eslint-disable-next-line max-len
        `${appId}${BOOKING_URLS.booking}/management/${bookingId}/${slotId}?name=${name}&phoneNumber=${phone}&email=${email}&selectedDate=${date}`,
    )

export const getBookingBill = ({
    appId,
    bookingId,
    timeSlotId,
}: {
    appId: string
    bookingId: string
    timeSlotId: string
}) =>
    axiosInstance.post(
        `${appId}${BOOKING_URLS.booking}/management/${bookingId}/${timeSlotId}/billing`,
    )
