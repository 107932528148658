/* eslint-disable max-len */

// 1 comment with text for regex
// 2 regex

export const MAIN_REGEX = {
    // Must Contain 8 Characters, One Number and One Letter (Please Note: Only Latin Characters Allowed)
    passwordRegex: /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,}$/,
    anyLettersRegex: /^[A-Za-zА-Яа-яІіЇї-\s]+$/,
    // Only Letters Allowed
    onlyLettersRegex: /^([ ]+)?[a-zA-Z-' ]+([ ]+)?$/,
    lettersWithSpacesRegex: /^([ ]+)?[a-zA-Z ]+([ ]+)?$/,
    lettersWithSpCharactersRegex: /^([ ]+)?[A-Za-z0-9'.\-\s, ]+([ ]+)?$/,
    // Enter correct Url!
    urlRegex: /$/,
    // Invalid Date!
    dateRegex: /$/,
    // Package name is invalid
    packageRegex: /^com\.(?!module)[a-zA-Z0-9]*\.(?!module)[a-zA-Z0-9]*$/,
    packageRegexNew:
        /^[a-zA-Z]{3}\.(?!module)[a-zA-Z0-9]*\.(?!module)[a-zA-Z0-9]*$/,
    // Max length 50 characters!
    max50regex: /^([ ]+)?.{1,50}([ ]+)?$/,
    max80regex: /^([ ]+)?.{1,80}([ ]+)?$/,
    coordRegex: /^-?([ ]+)?[\d.]+([ ]+)?$/gm,
    // Data in html regex
    htmlRegex: /(<([^>]+)>)/gi,
    // Email regex
    emailRegex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    priceRegex: /^[1-9]\d*(\.\d+)?$/g,
    number: /^\d+$/g,
    phoneNumber: /^\+?[1-9]\d{1,14}$/g,
}

export const SOCIAL_MEDIA_REGEX = {
    smm: {
        facebook: /^(http(s)?:\/\/)?(www.)?facebook\.com\/[\w.?=%-/]+$/gm,
        linkedIn:
            /^(http(s)?:\/\/)?(www\.|[a-z][a-z]\.)?linkedin\.com\/(in\/)?[\w/\-%/]+$/gm,
        pinterest:
            /^(http(s)?:\/\/)?(www.)?(pin\.it|pinterest\.(com|[a-z][a-z]))\/[\w/]+$/gm,
        instagram:
            /^(http(s)?:\/\/)?(www\.)?instagram\.com\/[\w.]+([\w.?\-&=%/]+)?$/gm,
        twitter: /^(http(s)?:\/\/)?(www\.)?twitter\.com\/[\w/]+([\w?=&]+)?$/gm,
        mXTakatak: /^(http(s)?:\/\/)?(www.)?mxtakatak\.com\/xt0\.[\w.]+$/gm,
        moj: /^(http(s)?:\/\/)?(www.)?mojapp\.in\/@[\w]+([?][\w=\-%]+)?$/gm,
        discord: /^(http(s)?:\/\/)?(www.)?discord(app)?\.com\/users\/[\d]+$/gm,
        tikTok: /^(http(s)?:\/\/)?(www.)?tiktok\.com\/@.+$/gm,
        reddit: /^(http(s)?:\/\/)?(www.)?reddit\.com\/(r|u(ser)?)\/[\w\-/]+$/gm,
        youTube:
            /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/|playlist\?list=|user\/[\w#@]+\/|channel\/[\w#@]+\/|c\/|shorts\/)?([A-Za-z0-9_#@-]+)(?:\S+)?$/gm,
    },
    chat: {
        messenger:
            /^(http(s)?:\/\/)?(www.)?(m\.me|messenger\.com)\/([\w]+\/)?[\w+.]+(\/)?$/gm,
        telegram:
            /^(http(s)?:\/\/)?(www.)?(t(elegram)?\.me|telegram\.org)\/[\w+.]+$/gm,
        viber: /^(http(s)?:\/\/)?(www\.)?invite\.viber\.com\/[\w+?&%=]+$/gm,
        whatsApp:
            /^(http(s)?:\/\/)?(www\.)?(chat\.whatsapp\.com|wa\.me)\/[\w]+$/gm,
        email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    },
    conference: {
        zoom: /^(http(s)?:\/\/)?(www\.)?us0[\d]web\.zoom\.us\/j\/[\w/&?#%=.-]+$/gm,
        teamViewer: /^(http(s)?:\/\/)?(www\.)?teamviewer\.com\/[\w/]+$/gm,
        skype: /^(http(s)?:\/\/)?(www\.)?(join\.)?skype\.com\/[\w/]+$/gm,
        googleMeet:
            /^(http(s)?:\/\/)?(www\.)?meet\.google\.com\/[\w/&?#%=.-]+$/gm,
        microsoftTeams:
            /^(http(s)?:\/\/)?(www\.)?(teams\.)?microsoft\.com\/[\w/&?#%=.-]+$/gm,
        adobe: /^(http(s)?:\/\/)?(www\.)?adobe\.com\/[\w/.]+$/gm,
        intermedia: /^(http(s)?:\/\/)?(www\.)?intermedia\.com\/[\w/-=-?]+$/gm,
        genesys: /^(http(s)?:\/\/)?(www\.)?genesys\.com\/[\w/-=-?]+$/gm,
    },
    video: {
        twitch: /^(http(s)?:\/\/)?(www\.)?twitch\.tv\/(videos\/)?[\w]+$/gm,
        dailymotion:
            /^(http(s)?:\/\/)?(www\.)?(dailymotion\.com|dai\.ly)(\/video)?\/x[a-z0-9]+(\?playlist=[a-z0-9]+)?$/gm,
        ted: /^(http(s)?:\/\/)?(www.)?ted\.com\/talks\/[a-z0-9_]+$/gm,
        tikTok: /^(http(s)?:\/\/)?(www\.)?tiktok\.com\/@[\w.]+\/video\/[\d]+(\?[\w&=]+)?$/gm,
        youTube:
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/gm,
        vimeo: /^(http(s)?:\/\/)?(www\.)?vimeo\.com\/[\d]+$/gm,
        instagram:
            /^(http(s)?:\/\/)?(www.)?instagram\.com\/(tv|reel|p)\/[\w-]+\/([\w=&?]+)?$/gm,
        pinterest:
            /^(http(s)?:\/\/)?(www.)?(pin\.it|pinterest\.(com|[a-z][a-z]))\/pin\/[\d/]+$/gm,
        facebook:
            /^(http(s)?:\/\/)?(www\.)?(fb\.watch|facebook\.com\/[\w.]+\/videos)\/[\w/]+$/gm,
    },
    cloud: {
        googleDrive:
            /^(http(s)?:\/\/)?(www\.)?drive\.google\.com\/(file\/d|drive\/(folders|u)\/[\w]+)\/[\w-]+((\/view)?\?[\w=&-/]+)?$/gm,
        oneDrive:
            /^(http(s)?:\/\/)?(www\.)?(1drv\.ms|[\w\-.]+)\/([\w\-:/]+)?[\w!?:=/]+$/gm,
        dropbox:
            /^(http(s)?:\/\/)?(www\.)?dropbox\.com\/[a-z]+\/[\w]+\/[\wА-яЁё%-=?]+$/gm,
        iCloud: /^(http(s)?:\/\/)?(www\.)?icloud\.com\/iclouddrive([\w/=\-&#.?]+)?$/gm,
        iDrive: /^(http(s)?:\/\/)?(www\.)?idrive\.com\/[\w/&?=%-]+$/gm,
        tresorit: /^(http(s)?:\/\/)?(www\.)?tresorit\.com\/[\w/&?=#%-]+$/gm,
        mega: /^(http(s)?:\/\/)?(www\.)?mega\.nz\/(file|folder)\/[\w/&?#=%-]+$/gm,
        amazon: /^(http(s)?:\/\/)?(www\.)?amazon\.com\/[\w/&?#%=-]+$/gm,
        sync: /^(http(s)?:\/\/)?(www\.)?(ln[\d]\.)?sync\.com\/(dl\/)?[\w/&?#%=-]+$/gm,
    },
}
