import { PATH_DESIGN, PATH_FEATURES } from 'config/paths'

export const data = [
    {
        key: 'blog',
        path: PATH_FEATURES.blog.root,
        component: 'blog',
        name: 'emulator:header:blog',
    },
    {
        key: 'blog-1',
        path: PATH_FEATURES.blog.create,
        component: 'blog',
        name: 'emulator:header:blog',
    },
    {
        key: 'blog-2',
        path: `${PATH_FEATURES.blog.edit}/:id`,
        component: 'blog-edit',
        name: 'emulator:header:blog',
    },
    {
        key: 'billing',
        path: `${PATH_FEATURES.paymentLink.root}`,
        component: 'billing',
        name: 'Billing',
    },
    {
        key: 'billing-1',
        path: `${PATH_FEATURES.paymentLink.create}`,
        component: 'billing',
        name: 'Billing',
    },
    {
        key: 'billing-2',
        path: `${PATH_FEATURES.paymentLink.edit}/:id`,
        component: 'billing-edit',
        name: 'Billing',
    },
    {
        key: 'news',
        path: PATH_FEATURES.news.root,
        component: 'news',
        name: 'emulator:header:news',
    },
    {
        key: 'news-1',
        path: PATH_FEATURES.news.create,
        component: 'news',
        name: 'emulator:header:news',
    },
    {
        key: 'news-2',
        path: `${PATH_FEATURES.news.edit}/:id`,
        component: 'news-edit',
        name: 'emulator:header:news',
    },
    {
        key: 'calendar',
        path: PATH_FEATURES.calendar.root,
        component: 'calendar',
        name: 'emulator:header:calendar',
    },
    {
        key: 'calendar-1',
        path: PATH_FEATURES.calendar.createEvent,
        component: 'calendar',
        name: 'emulator:header:calendar',
    },
    {
        key: 'calendar-2',
        path: `${PATH_FEATURES.calendar.editEvent}/:id`,
        component: 'calendar',
        name: 'emulator:header:calendar',
    },
    {
        key: 'gallery',
        path: PATH_FEATURES.gallery.root,
        component: 'gallery',
        name: 'Gallery',
    },
    {
        key: 'gallery-1',
        path: PATH_FEATURES.gallery.addPicture,
        component: 'gallery',
        name: 'Gallery',
    },
    {
        key: 'gallery-2',
        path: `${PATH_FEATURES.gallery.edit}/:id`,
        component: 'gallery-edit',
        name: 'Gallery',
    },
    {
        key: 'information',
        path: PATH_FEATURES.information.root,
        component: 'about',
        name: 'emulator:header:about',
    },
    {
        key: 'information-1',
        path: PATH_FEATURES.information.create,
        component: 'about',
        name: 'emulator:header:about',
    },
    {
        key: 'information-2',
        path: PATH_FEATURES.information.createMultiple,
        component: 'about',
        name: 'emulator:header:about',
    },
    {
        key: 'information-3',
        path: `${PATH_FEATURES.information.edit}/:id`,
        component: 'about-edit',
        name: 'emulator:header:about',
    },
    {
        key: 'service',
        path: PATH_FEATURES.services.root,
        component: 'service',
        name: 'emulator:header:services',
    },
    {
        key: 'service-1',
        path: PATH_FEATURES.services.create,
        component: 'service',
        name: 'emulator:header:services',
    },
    {
        key: 'service-2',
        path: `${PATH_FEATURES.services.edit}/:id`,
        component: 'service-edit',
        name: 'emulator:header:services',
    },
    {
        key: 'employees',
        path: PATH_FEATURES.employees.root,
        component: 'employees',
        name: 'emulator:header:employee',
    },
    {
        key: 'employees-1',
        path: `${PATH_FEATURES.employees.edit}/:id`,
        component: 'employee-edit',
        name: 'emulator:header:employee',
    },
    {
        key: 'employees-2',
        path: PATH_FEATURES.employees.create,
        component: 'employee-create',
        name: 'emulator:header:employee',
    },
    {
        key: 'booking',
        path: PATH_FEATURES.booking.root,
        component: 'booking',
        name: 'emulator:header:booking',
    },
    {
        key: 'booking-1',
        path: PATH_FEATURES.booking.create,
        component: 'booking',
        name: 'emulator:header:booking',
    },
    {
        key: 'booking-2',
        path: `${PATH_FEATURES.booking.edit}/:id`,
        component: 'booking-edit',
        name: 'emulator:header:booking',
    },
    {
        key: 'booking-3',
        path: `${PATH_FEATURES.booking.management}/:id`,
        component: 'booking-managment',
        name: 'emulator:header:booking',
    },
    {
        key: 'booking-4',
        path: `${PATH_FEATURES.booking.managementItem}/:id`,
        component: 'booking-managment',
        name: 'emulator:header:booking',
    },
    {
        key: 'map',
        path: PATH_FEATURES.map.root,
        component: 'map',
        name: 'emulator:header:map',
    },
    {
        key: 'map-1',
        path: PATH_FEATURES.map.create,
        component: 'map',
        name: 'emulator:header:map',
    },
    {
        key: 'map-2',
        path: `${PATH_FEATURES.map.edit}/:id`,
        component: 'map-edit',
        name: 'emulator:header:map',
    },
    {
        key: 'e-reader',
        path: PATH_FEATURES.eReader.root,
        component: 'e-reader',
        name: 'emulator:header:eReader',
    },
    {
        key: 'e-reader-1',
        path: PATH_FEATURES.eReader.create,
        component: 'e-reader',
        name: 'emulator:header:eReader',
    },
    {
        key: 'e-reader-2',
        path: `${PATH_FEATURES.eReader.edit}/:id`,
        component: 'e-reader',
        name: 'emulator:header:eReader',
    },
    {
        key: 'cloud',
        path: PATH_FEATURES.cloud.root,
        component: 'cloud',
        name: 'emulator:header:cloud',
    },
    // {
    //     key: 'video',
    //     path: PATH_FEATURES.cloud.root,
    //     component: 'video',
    //     name: 'Video',
    // },
    // {
    //     key: 'conference',
    //     path: PATH_FEATURES.cloud.root,
    //     component: 'conference',
    //     name: 'Conference',
    // },
    // {
    //     key: 'smm',
    //     path: PATH_FEATURES.cloud.root,
    //     component: 'smm',
    //     name: 'Smm',
    // },
    // {
    //     key: 'chat-links',
    //     path: PATH_FEATURES.cloud.root,
    //     component: 'chat',
    //     name: 'Chat',
    // },
    {
        key: 'survey',
        path: PATH_FEATURES.test.root,
        component: 'surveyList',
        name: 'survey:title',
    },
    {
        key: 'survey-1',
        path: PATH_FEATURES.test.analytics,
        component: 'surveyAnalytics',
        name: 'survey:title',
    },
    {
        key: 'survey-2',
        path: PATH_FEATURES.test.create,
        component: 'surveyList',
        name: 'survey:title',
    },
    {
        key: 'survey-3',
        path: `${PATH_FEATURES.test.edit}/:id`,
        component: 'surveyItem',
        name: 'survey:title',
    },
    {
        key: 'survey-4',
        path: `${PATH_FEATURES.test.root}/:id/statistic`,
        component: 'surveyList',
        name: 'survey:title',
    },
    {
        key: 'survey-4',
        path: `${PATH_FEATURES.test.root}/:id/users`,
        component: 'surveyList',
        name: 'survey:title',
    },
    {
        key: 'todo',
        path: PATH_FEATURES.premium.todo.root,
        image: undefined,
        component: 'todo',
        name: 'Todo',
    },
    {
        key: 'chat-premium',
        path: PATH_FEATURES.premium.chat.root,
        component: undefined,
        name: '',
    },
    {
        key: 'forum',
        path: PATH_FEATURES.premium.forum.themes.root,
        component: undefined,
        name: '',
    },
    {
        key: 'forum-1',
        path: `${PATH_FEATURES.premium.forum.themes.edit}/:id`,
        component: undefined,
        name: '',
    },
    {
        key: 'forum-2',
        path: PATH_FEATURES.premium.forum.themes.create,
        component: undefined,
        name: '',
    },
    {
        key: 'forum-3',
        path: `${PATH_FEATURES.premium.forum.themes.root}/:id/users`,
        component: undefined,
        name: '',
    },
    {
        key: 'forum-4',
        path: `${PATH_FEATURES.premium.forum.themes.root}/:id/users/:id`,
        component: undefined,
        name: '',
    },
    {
        key: 'color-palette',
        path: PATH_DESIGN.colorPalette,
        image: undefined,
        component: 'welcome',
        name: '',
    },
    {
        key: 'background',
        path: PATH_DESIGN.background,
        image: undefined,
        component: 'welcome',
        name: '',
    },
    {
        key: 'launch-screen',
        path: PATH_DESIGN.launchScreen,
        image: undefined,
        component: 'welcome',
        name: '',
    },
    {
        key: 'icon',
        path: PATH_DESIGN.icon,
        component: 'icon',
        name: '',
    },
    {
        key: 'payments',
        path: PATH_FEATURES.payments.root,
        component: 'payments',
        name: 'emulator:header:payments',
    },
    {
        key: 'payments-1',
        path: `${PATH_FEATURES.payments.payment}/:id`,
        component: 'payment',
        name: 'emulator:header:payments',
    },
    {
        key: 'payments-2',
        path: PATH_FEATURES.payments.create,
        component: 'payments',
        name: 'emulator:header:payments',
    },
    {
        key: 'payments-3',
        path: `${PATH_FEATURES.payments.edit}/:id`,
        component: 'payment',
        name: 'emulator:header:payments',
    },
]
