import { lazy } from 'react'

import { PATH_TUTORIAL } from 'config/paths'

export const TutorialRoutes = [
    {
        id: 1,
        module: 'tutorial',
        protected: true,
        exact: true,
        path: PATH_TUTORIAL.root,
        component: lazy(() => import('containers/tutorial/Tutorial')),
    },
    {
        id: 2,
        module: 'tutorial',
        protected: true,
        exact: true,
        path: `${PATH_TUTORIAL.root}/:name`,
        component: lazy(
            () => import('containers/tutorial/tutorial-item/TutorialItem'),
        ),
    },
    // {
    //     id: 2,
    //     module: 'tutorial',
    //     protected: true,
    //     exact: true,
    //     path: `${PATH_TUTORIAL.root}/:name`,
    //     component: lazy(
    //         () => import('containers/tutorial/video-list/VideoList'),
    //     ),
    // },
    // {
    //     id: 3,
    //     module: 'tutorial',
    //     protected: true,
    //     exact: true,
    //     path: `${PATH_TUTORIAL.root}/:name/:listId/:videoId`,
    //     component: lazy(
    //         () => import('containers/tutorial/video-page/VideoPage'),
    //     ),
    // },
]
