import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
// import { useMediaQuery } from '@mui/material'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
// import {
//     HubConnectionBuilder,
//     LogLevel,
//     HttpTransportType,
// } from '@microsoft/signalr'

import { PATH_FEATURES } from 'config/paths'
import { TError } from 'config/types'

import { useLocalStorageBoolean } from 'hooks/useLocalStorageBoolean'
import { RootState } from 'redux/reducers/rootReducer'

// Maybe make cookie popup with Popup component
import Cookie from 'components/cookie/Cookie'

import AiPopup from './components/ai-popup/AiPopup'
import Navigation from './components/navigation/Navigation'
import RightPanel from './right-panel/RightPanel'
import HeaderNavigation from '../header-navigation/HeaderNavigation'
// eslint-disable-next-line max-len
import { fetchApplicationsRequest } from '../../containers/app-list/store/actions'

import {
    fetchNotesAction,
    SetNotesAction,
} from '../header-navigation/store/action'

import { data } from './data'

const Dashboard = ({
    children,
    type,
}: {
    children: React.ReactNode
    type: 'dashboard' | 'constructor'
}): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast'])

    const dispatch = useDispatch()

    const { pathname } = useLocation()
    const { applications } = useSelector(
        (state: RootState) => state.applications,
    )
    const { showAiPopup, showAd } = useSelector(
        (state: RootState) => state.menus,
    )

    const cookies = useCookies([
        'isTrialUsed',
        'isExpired',
        'paid_plan',
        'active_features',
    ])[0]
    const [isCookieAccepted, setIsCookieAccepted] = useLocalStorageBoolean(
        'cookie',
        false,
    )

    const [isCookieVisible, setIsCookieVisible] = useState(!isCookieAccepted)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const [connection, setConnection] = useState<any>(null)

    const localApplications = localStorage.getItem('applications')

    // Need applications for notifications
    useEffect(() => {
        // causes redirect with 403 if new account so need paid plan
        if (
            !localApplications &&
            cookies.paid_plan &&
            cookies.isExpired !== 'true'
        ) {
            dispatch(
                fetchApplicationsRequest({
                    callback: (callbackType: string, errorArray?: TError[]) => {
                        if (callbackType === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
        }
    }, [localApplications, dispatch, cookies.paid_plan, cookies.isExpired, t])

    useEffect(() => {
        if (!applications) return
        localStorage.setItem('applications', JSON.stringify(applications))
    }, [applications])

    // useEffect(() => {
    //     const newConnection = new HubConnectionBuilder()
    //         .configureLogging(LogLevel.Debug)
    //         .withUrl(
    //             // eslint-disable-next-line max-len
    //             'https://ym-api-dev.azurewebsites.net/build',
    //             {
    //                 skipNegotiation: true,
    //                 transport: HttpTransportType.WebSockets,
    //             },
    //         )
    //         .build()

    //     setConnection(newConnection)
    // }, [])

    // useEffect(() => {
    //     if (connection) {
    //         connection
    //             .start()
    //             .then(() => {
    //                 console.log('Connected!')
    //                 // eslint-disable-next-line max-len
    //                 connection.on('buildUpdate', (notification: any) => {
    //                     console.log('notification', notification)
    //                 })
    //             })
    //             .catch((e: any) => console.log('Connection failed: ', e))
    //     }
    // }, [connection])

    useEffect(() => {
        dispatch(fetchNotesAction({ lang: i18n.language }))

        return () => {
            dispatch(
                SetNotesAction({
                    notifications: undefined,
                }),
            )
        }
    }, [dispatch, i18n])

    const handleAcceptCookie = () => {
        setIsCookieAccepted(!isCookieAccepted)
        setIsCookieVisible(!isCookieVisible)
    }

    const handleCloseCookie = () => {
        setIsCookieVisible(!isCookieVisible)
    }

    const removePreviewByPath = data.find(
        dataItem =>
            pathname.includes(dataItem.path) || pathname === PATH_FEATURES.root,
    )

    const noActiveFeatures = cookies?.active_features?.length === 0

    // const queryMax = useMediaQuery('(max-width:960px)')

    return (
        <>
            <HeaderNavigation />
            {/* standard case */}
            <div
                className={`${
                    // (cookies.isTrialUsed === 'false' ||
                    //     cookies.isExpired === 'true') &&
                    showAd
                        ? 'dashboard-constructor--sale'
                        : 'dashboard-constructor'
                }`}
            >
                {/* sale case */}

                {/* <div
                className={`${
                    showAd
                        ? 'dashboard-constructor--sale '
                        : 'dashboard-constructor '
                }
                `}
            > */}
                {type === 'dashboard' ? <Navigation /> : null}
                <div
                    className={`dashboard-view ${
                        pathname.includes('app-name') ||
                        pathname.includes('analytics')
                            ? 'dashboard-view--less-padding'
                            : ''
                    } ${
                        !pathname.includes('dashboard')
                            ? 'dashboard-view--wide'
                            : ''
                    } ${
                        pathname.includes('features') && noActiveFeatures
                            ? 'dashboard-view--no-padding'
                            : ''
                    }`}
                >
                    {children}
                </div>
                {/* If dashboard */}
                {type === 'dashboard'
                    ? !removePreviewByPath && <RightPanel />
                    : null}
            </div>
            {isCookieVisible && (
                <Cookie
                    handleClick={handleAcceptCookie}
                    handleClose={handleCloseCookie}
                />
            )}
            {showAiPopup ? <AiPopup /> : null}
        </>
    )
}

export default Dashboard
