import React from 'react'

export const PhoneBig = (): React.ReactElement => (
    <svg
        width='304'
        height='628'
        viewBox='0 0 304 628'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='new-loader__iphone'
    >
        <path
            d='M257.102 0H47.0607C22.2091 0 2.06372 20.5048 2.06372 45.7996V582.117C2.06372 607.412 22.2091 627.917 47.0607 627.917H257.102C281.953 627.917 302.099 607.412 302.099 582.117V45.7996C302.099 20.5048 281.953 0 257.102 0ZM290.788 584.386C290.788 599.176 275.266 615.311 261.065 615.311H43.1802C28.9794 615.311 13.4574 599.092 13.4574 584.386V43.0264C13.4574 28.2361 28.9794 12.1012 43.1802 12.1012H70.1785C71.2518 12.1012 72.16 12.9415 72.16 14.034V17.3954C72.16 22.6057 76.701 33.9505 90.2413 33.9505H214.994C227.214 33.9505 232.498 22.7737 232.498 17.0593V14.034C232.498 13.0256 233.406 12.1012 234.479 12.1012H260.982C275.183 12.1012 290.705 28.3201 290.705 43.0264V584.386H290.788Z'
            fill='#737373'
        />
        <path
            d='M254.954 3.3606H49.289C24.9328 3.3606 5.2002 23.6133 5.2002 48.656V579.259C5.2002 604.302 24.9328 624.554 49.289 624.554H254.954C279.31 624.554 299.043 604.218 299.043 579.259V48.74C299.043 23.6973 279.31 3.44463 254.954 3.44463V3.3606ZM290.787 584.385C290.787 599.175 275.265 615.31 261.064 615.31H43.1793C28.9785 615.31 13.4565 599.091 13.4565 584.385V43.0256C13.4565 28.2352 28.9785 12.1003 43.1793 12.1003H70.1776C71.2509 12.1003 72.1591 12.9407 72.1591 14.0332V17.3946C72.1591 22.6048 76.7001 33.9497 90.2405 33.9497H214.994C227.213 33.9497 232.497 22.7729 232.497 17.0585V14.0332C232.497 13.0247 233.405 12.1003 234.479 12.1003H260.981C275.182 12.1003 290.704 28.3193 290.704 43.0256V584.385H290.787Z'
            fill='#0D0D0D'
        />
        <path
            opacity='0.6'
            d='M274.773 8.40233C276.92 9.41076 278.902 10.5873 280.801 11.9318C286.91 14.2008 291.782 17.8144 295.827 23.2767C293.02 18.4026 289.305 14.2008 284.929 10.6713C281.709 8.65443 278.819 8.06618 274.773 8.40233Z'
            fill='url(#paint0_linear_3188_12191)'
        />
        <path
            opacity='0.3'
            d='M300.944 308.748V46.3876C300.944 37.984 298.632 30.0846 294.669 23.2777C290.623 17.8154 285.752 14.2018 279.643 11.9329C290.376 19.4961 297.476 32.1015 297.476 46.3876V308.832H300.944V308.748Z'
            fill='url(#paint1_linear_3188_12191)'
        />
        <path
            opacity='0.5'
            d='M29.8901 8.40233C27.7434 9.41076 25.7619 10.5873 23.8629 11.9318C17.7533 14.2008 12.882 17.8144 8.83643 23.2767C11.6436 18.4026 15.3589 14.2008 19.7348 10.6713C22.9548 8.65443 25.8445 8.06618 29.8901 8.40233Z'
            fill='url(#paint2_linear_3188_12191)'
        />
        <path
            opacity='0.3'
            d='M3.7168 307.907V46.3871C3.7168 37.9835 6.02859 30.0841 9.99163 23.2772C14.0372 17.8149 18.9085 14.2013 25.0182 11.9324C14.2849 19.4956 7.18447 32.101 7.18447 46.3871V307.991H3.7168V307.907Z'
            fill='url(#paint3_linear_3188_12191)'
        />
        <path
            opacity='0.3'
            d='M256.441 1.0918H48.1341C37.8137 1.0918 28.3189 4.70534 20.8057 10.6719C24.0256 8.65503 26.9153 8.06678 30.9609 8.40293C36.1624 5.96588 42.0244 4.53727 48.1341 4.53727H256.441C262.551 4.53727 268.413 5.96588 273.615 8.40293C277.66 8.06678 280.55 8.65503 283.77 10.6719C276.257 4.62131 266.762 1.0918 256.441 1.0918Z'
            fill='url(#paint4_linear_3188_12191)'
        />
        <path
            d='M274.688 619.514C276.835 618.506 278.817 617.329 280.716 615.985C286.825 613.716 291.696 610.102 295.742 604.64C292.935 609.514 289.22 613.716 284.844 617.245C281.624 619.262 278.734 619.85 274.688 619.514Z'
            fill='url(#paint5_linear_3188_12191)'
        />
        <path
            opacity='0.3'
            d='M300.944 308.666V581.615C300.944 590.018 298.632 597.918 294.669 604.725C290.623 610.187 285.752 613.801 279.643 616.069C290.376 608.506 297.476 595.901 297.476 581.615V308.666H300.944Z'
            fill='url(#paint6_linear_3188_12191)'
        />
        <path
            opacity='0.5'
            d='M30.1366 619.514C27.99 618.506 26.0085 617.329 24.1095 615.985C17.9998 613.716 13.1286 610.102 9.08301 604.64C11.8902 609.514 15.6055 613.716 19.9814 617.245C23.2013 619.262 26.091 619.85 30.1366 619.514Z'
            fill='url(#paint7_linear_3188_12191)'
        />
        <path
            opacity='0.3'
            d='M3.7168 307.741V581.614C3.7168 590.018 6.02859 597.917 9.99163 604.724C14.0372 610.186 18.9085 613.8 25.0182 616.069C14.2849 608.505 7.18447 595.9 7.18447 581.614V307.741H3.7168Z'
            fill='url(#paint8_linear_3188_12191)'
        />
        <path
            opacity='0.3'
            d='M256.441 626.91H48.1341C37.8137 626.91 28.3189 623.296 20.8057 617.33C24.0256 619.347 26.9153 619.935 30.9609 619.599C36.1624 622.036 42.0244 623.464 48.1341 623.464H256.441C262.551 623.464 268.413 622.036 273.615 619.599C277.66 619.935 280.55 619.347 283.77 617.33C276.257 623.38 266.762 626.91 256.441 626.91Z'
            fill='url(#paint9_linear_3188_12191)'
        />
        <path
            opacity='0.5'
            d='M257.266 622.117H47.968C25.841 622.117 7.9248 603.881 7.9248 581.36V45.8828C7.9248 23.5292 25.7585 5.37744 47.7203 5.37744H256.688C296.484 5.37744 296.814 47.2273 296.814 46.2189V581.948C296.814 604.134 279.145 622.117 257.349 622.117H257.266ZM47.4726 6.80605C26.419 6.80605 9.32837 24.2015 9.32837 45.6307V581.36C9.32837 603.125 26.6667 620.773 48.0506 620.773H257.349C278.402 620.773 295.41 603.377 295.41 582.032V46.2189C295.41 24.4536 278.072 6.80605 256.688 6.80605H47.4726Z'
            fill='url(#paint10_linear_3188_12191)'
        />
        <g opacity='0.6'>
            <path
                d='M176.19 18.7385C176.19 18.7385 175.943 18.2349 175.777 17.9832L175.612 18.151L176.19 18.7385Z'
                fill='#1A1A1A'
            />
            <path
                d='M137.549 20.8411L136.806 21.5134L137.384 22.1016H137.714L138.21 21.5134L137.549 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M136.229 22.1036H136.56L136.395 21.9343L136.229 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M171.91 19.7432L171.209 20.4578L171.911 21.1716L172.612 20.457L171.91 19.7432Z'
                fill='#1A1A1A'
            />
            <path
                d='M169.625 19.7392L168.923 20.4534L169.625 21.1676L170.327 20.4534L169.625 19.7392Z'
                fill='#1A1A1A'
            />
            <path
                d='M155.206 18.5488L154.504 19.2629L155.206 19.9771L155.907 19.263L155.206 18.5488Z'
                fill='#1A1A1A'
            />
            <path
                d='M175.363 17.5631C175.363 17.5631 174.785 17.1435 174.455 17.0596L175.116 17.731L175.281 17.5631H175.363Z'
                fill='#1A1A1A'
            />
            <path
                d='M141.925 20.8411L141.264 21.5134L141.76 22.1016H142.09L142.668 21.5134L141.925 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M135.32 20.8411L134.577 21.5134L135.155 22.1016H135.485L135.98 21.5134L135.32 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M138.458 22.1036H138.789L138.624 21.9343L138.458 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M172.967 20.8411L172.306 21.5134L172.802 22.1016H173.132L173.71 21.5134L172.967 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M140.684 22.1036H141.015L140.85 21.9343L140.684 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M175.943 20.7575C175.943 20.7575 176.273 20.0852 176.273 19.749L175.612 20.4213L175.943 20.7575Z'
                fill='#1A1A1A'
            />
            <path
                d='M174.455 21.5134L174.785 21.8495C174.785 21.8495 175.363 21.5133 175.611 21.2612L175.198 20.8411L174.455 21.5974V21.5134Z'
                fill='#1A1A1A'
            />
            <path
                d='M139.695 20.8411L139.035 21.5134L139.53 22.1016H139.86L140.438 21.5134L139.695 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M157.442 18.5404L156.741 19.2546L157.442 19.9688L158.144 19.2546L157.442 18.5404Z'
                fill='#1A1A1A'
            />
            <path
                d='M166.361 17.7317L167.022 17.0594L166.939 16.8914H165.701L165.618 17.0594L166.361 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M160.761 19.7692L160.059 20.4834L160.761 21.1976L161.462 20.4834L160.761 19.7692Z'
                fill='#1A1A1A'
            />
            <path
                d='M150.791 18.506L150.089 19.2202L150.791 19.9344L151.492 19.2202L150.791 18.506Z'
                fill='#1A1A1A'
            />
            <path
                d='M130.861 20.8411L130.118 21.5974L130.613 22.1017C130.613 22.1017 130.696 22.1017 130.778 22.1017H130.943L131.521 21.5134L130.778 20.7571L130.861 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M162.999 19.7611L162.297 20.4753L162.999 21.1895L163.7 20.4753L162.999 19.7611Z'
                fill='#1A1A1A'
            />
            <path
                d='M157.857 20.4216L158.6 21.1779L159.261 20.4216L158.6 19.6653L157.857 20.4216Z'
                fill='#1A1A1A'
            />
            <path
                d='M172.967 17.7317L173.71 17.0594L173.627 16.8914H172.389L172.306 17.0594L172.967 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M129.713 19.7383L129.012 20.4534L129.715 21.1667L130.416 20.4516L129.713 19.7383Z'
                fill='#1A1A1A'
            />
            <path
                d='M164.132 17.7317L164.875 17.0594L164.71 16.8914H163.471L163.389 17.0594L164.132 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M133.09 20.8411L132.347 21.5134L132.925 22.1016H133.255L133.833 21.5134L133.09 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M167.42 19.649L166.718 20.3628L167.42 21.0774L168.122 20.3635L167.42 19.649Z'
                fill='#1A1A1A'
            />
            <path
                d='M170.737 17.7317L171.48 17.0594L171.398 16.8914H170.159L170.077 17.0594L170.737 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M152.968 18.5568L152.266 19.271L152.968 19.9852L153.669 19.271L152.968 18.5568Z'
                fill='#1A1A1A'
            />
            <path
                d='M134 22.1036H134.331L134.165 21.9343L134 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M164.546 20.4216L165.206 21.1779L165.95 20.4216L165.206 19.6653L164.546 20.4216Z'
                fill='#1A1A1A'
            />
            <path
                d='M168.591 17.7317L169.251 17.0594L169.168 16.8914H167.93L167.847 17.0594L168.591 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M131.852 22.103H132.1L131.934 21.9343L131.852 22.103Z'
                fill='#1A1A1A'
            />
            <path
                d='M169.5 22.1036H169.831L169.666 21.9343L169.5 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M159.676 20.8411L158.933 21.5134L159.511 22.1016H159.841L160.419 21.5134L159.676 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M166.361 20.0004L167.022 19.3281L166.361 18.5718L165.618 19.3281L166.361 20.0004Z'
                fill='#1A1A1A'
            />
            <path
                d='M149.521 22.1036H149.852L149.687 21.9343L149.521 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M158.438 22.1036H158.769L158.604 21.9343L158.438 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M161.833 18.5265L161.131 19.2407L161.833 19.9549L162.534 19.2407L161.833 18.5265Z'
                fill='#1A1A1A'
            />
            <path
                d='M161.907 20.8411L161.164 21.5134L161.742 22.1016H162.072L162.65 21.5134L161.907 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M160.667 22.103H160.916L160.833 21.9343L160.667 22.103Z'
                fill='#1A1A1A'
            />
            <path
                d='M171.729 22.1036H172.06L171.894 21.9343L171.729 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M153.07 20.8411L152.327 21.5134L152.905 22.1016H153.235L153.73 21.5134L153.07 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M168.484 18.5632L167.783 19.2773L168.484 19.9915L169.186 19.2774L168.484 18.5632Z'
                fill='#1A1A1A'
            />
            <path
                d='M153.98 22.1036H154.311L154.146 21.9343L153.98 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M155.216 20.8411L154.556 21.5134L155.051 22.1016H155.381L155.959 21.5134L155.216 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M164.071 18.5185L163.369 19.2327L164.071 19.9469L164.773 19.2326L164.071 18.5185Z'
                fill='#1A1A1A'
            />
            <path
                d='M150.839 20.8411L150.096 21.5134L150.674 22.1016H151.004L151.5 21.5134L150.839 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M157.446 20.8411L156.785 21.5134L157.281 22.1016H157.611L158.189 21.5134L157.446 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M156.209 22.1036H156.54L156.374 21.9343L156.209 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M148.611 20.8411L147.868 21.5134L148.446 22.1016H148.776L149.354 21.5134L148.611 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M144.152 20.8411L143.409 21.5134L143.987 22.1016H144.317L144.895 21.5134L144.152 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M168.591 20.8411L167.848 21.5134L168.343 22.1016H168.756L169.251 21.5134L168.591 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M161.907 17.7317L162.65 17.0594L162.485 16.8914H161.329L161.164 17.0594L161.907 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M159.628 18.5188L158.926 19.2327L159.628 19.9472L160.33 19.2334L159.628 18.5188Z'
                fill='#1A1A1A'
            />
            <path
                d='M162.812 22.1036H163.143L162.978 21.9343L162.812 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M172.967 20.0004L173.71 19.3281L172.967 18.5718L172.306 19.3281L172.967 20.0004Z'
                fill='#1A1A1A'
            />
            <path
                d='M170.738 20.8411L170.077 21.5134L170.573 22.1016H170.903L171.481 21.5134L170.738 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M167.271 22.1036H167.602L167.436 21.9343L167.271 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M142.913 22.1036H143.244L143.079 21.9343L142.913 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M164.132 20.8411L163.389 21.5134L163.967 22.1016H164.297L164.875 21.5134L164.132 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M146.381 20.8411L145.638 21.5134L146.216 22.1016H146.546L147.124 21.5134L146.381 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M147.291 22.1036H147.622L147.456 21.9343L147.291 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M145.143 22.103H145.392L145.309 21.9343L145.143 22.103Z'
                fill='#1A1A1A'
            />
            <path
                d='M166.361 20.8411L165.618 21.5134L166.196 22.1016H166.526L167.022 21.5134L166.361 20.8411Z'
                fill='#1A1A1A'
            />
            <path
                d='M170.722 18.5548L170.02 19.269L170.722 19.9832L171.423 19.269L170.722 18.5548Z'
                fill='#1A1A1A'
            />
            <path
                d='M165.042 22.1036H165.372L165.207 21.9343L165.042 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M151.75 22.1036H152.081L151.915 21.9343L151.75 22.1036Z'
                fill='#1A1A1A'
            />
            <path
                d='M130.798 18.5163L130.097 19.2305L130.798 19.9447L131.5 19.2305L130.798 18.5163Z'
                fill='#1A1A1A'
            />
            <path
                d='M134.202 19.7436L133.5 20.4578L134.202 21.172L134.903 20.4577L134.202 19.7436Z'
                fill='#1A1A1A'
            />
            <path
                d='M156.366 17.4315L155.664 18.1453L156.365 18.8598L157.067 18.146L156.366 17.4315Z'
                fill='#1A1A1A'
            />
            <path
                d='M139.695 17.7317L140.438 17.0594L140.356 16.8914H139.117L139.035 17.0594L139.695 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M154.138 17.4971L153.436 18.2104L154.136 18.9255L154.839 18.2122L154.138 17.4971Z'
                fill='#1A1A1A'
            />
            <path
                d='M135.32 17.7317L135.98 17.0594L135.898 16.8914H134.659L134.577 17.0594L135.32 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M151.948 17.4633L151.246 18.1775L151.948 18.8917L152.649 18.1775L151.948 17.4633Z'
                fill='#1A1A1A'
            />
            <path
                d='M131.964 19.7514L131.263 20.4656L131.964 21.1798L132.666 20.4656L131.964 19.7514Z'
                fill='#1A1A1A'
            />
            <path
                d='M137.549 17.7317L138.21 17.0594L138.127 16.8914H136.889L136.806 17.0594L137.549 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M133.037 18.508L132.335 19.2222L133.037 19.9364L133.738 19.2222L133.037 18.508Z'
                fill='#1A1A1A'
            />
            <path
                d='M144.152 17.7317L144.896 17.0594L144.73 16.8914H143.575L143.409 17.0594L144.152 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M147.473 17.4799L146.771 18.1941L147.473 18.9083L148.174 18.1941L147.473 17.4799Z'
                fill='#1A1A1A'
            />
            <path
                d='M138.652 19.7529L137.951 20.4675L138.653 21.1813L139.355 20.4668L138.652 19.7529Z'
                fill='#1A1A1A'
            />
            <path
                d='M141.925 17.7317L142.668 17.0594L142.585 16.8914H141.347L141.264 17.0594L141.925 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M149.71 17.4716L149.009 18.1858L149.71 18.9L150.412 18.1858L149.71 17.4716Z'
                fill='#1A1A1A'
            />
            <path
                d='M136.355 19.737L135.653 20.4512L136.355 21.1654L137.057 20.4512L136.355 19.737Z'
                fill='#1A1A1A'
            />
            <path
                d='M167.465 17.4694L166.763 18.1836L167.465 18.8978L168.167 18.1836L167.465 17.4694Z'
                fill='#1A1A1A'
            />
            <path
                d='M175.197 18.5383L174.496 19.2524L175.197 19.9666L175.899 19.2525L175.197 18.5383Z'
                fill='#1A1A1A'
            />
            <path
                d='M129.375 19.3281L128.632 18.5718L128.384 18.8239C128.384 18.8239 128.219 19.3281 128.219 19.5802C128.219 19.5802 128.219 19.6642 128.219 19.7483L128.549 20.0844L129.293 19.3281H129.375Z'
                fill='#1A1A1A'
            />
            <path
                d='M171.887 17.4417L171.185 18.1555L171.886 18.8701L172.588 18.1563L171.887 17.4417Z'
                fill='#1A1A1A'
            />
            <path
                d='M174.092 17.4474L173.391 18.1616L174.092 18.8758L174.794 18.1616L174.092 17.4474Z'
                fill='#1A1A1A'
            />
            <path
                d='M174.126 21.1779L174.786 20.4216L174.126 19.6653L173.383 20.4216L174.126 21.1779Z'
                fill='#1A1A1A'
            />
            <path
                d='M128.795 20.9253C128.795 20.9253 129.126 21.3478 129.375 21.5168L128.795 20.9253Z'
                fill='#1A1A1A'
            />
            <path
                d='M158.6 18.9091L159.261 18.1528L158.6 17.3965L157.857 18.1528L158.6 18.9091Z'
                fill='#1A1A1A'
            />
            <path
                d='M169.667 17.432L168.964 18.1453L169.665 18.8603L170.368 18.147L169.667 17.432Z'
                fill='#1A1A1A'
            />
            <path
                d='M133.09 17.7317L133.833 17.0594L133.668 16.8914H132.512L132.347 17.0594L133.09 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M163.036 17.5342L162.333 18.2476L163.034 18.9626L163.736 18.2493L163.036 17.5342Z'
                fill='#1A1A1A'
            />
            <path
                d='M160.805 17.5101L160.104 18.2246L160.806 18.9384L161.507 18.2239L160.805 17.5101Z'
                fill='#1A1A1A'
            />
            <path
                d='M140.83 19.7206L140.128 20.4348L140.83 21.149L141.531 20.4348L140.83 19.7206Z'
                fill='#1A1A1A'
            />
            <path
                d='M165.175 17.4637L164.473 18.1775L165.174 18.8921L165.876 18.1783L165.175 17.4637Z'
                fill='#1A1A1A'
            />
            <path
                d='M131.605 17.0598H130.862C130.862 17.0598 130.45 17.0598 130.202 17.0598L130.862 17.7312L131.605 16.9758V17.0598Z'
                fill='#1A1A1A'
            />
            <path
                d='M153.069 17.7317L153.73 17.0594L153.647 16.8914H152.409L152.326 17.0594L153.069 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M136.347 17.4589L135.645 18.1731L136.347 18.8873L137.048 18.1731L136.347 17.4589Z'
                fill='#1A1A1A'
            />
            <path
                d='M151.168 20.4216L151.911 21.1779L152.655 20.4216L151.911 19.6653L151.168 20.4216Z'
                fill='#1A1A1A'
            />
            <path
                d='M146.287 18.4864L145.586 19.2014L146.289 19.9147L146.99 19.1997L146.287 18.4864Z'
                fill='#1A1A1A'
            />
            <path
                d='M138.584 17.4516L137.882 18.1658L138.584 18.88L139.285 18.1658L138.584 17.4516Z'
                fill='#1A1A1A'
            />
            <path
                d='M149.718 19.7492L149.016 20.4634L149.718 21.1776L150.419 20.4634L149.718 19.7492Z'
                fill='#1A1A1A'
            />
            <path
                d='M157.446 17.7317L158.189 17.0594L158.106 16.8914H156.868L156.785 17.0594L157.446 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M155.216 17.7317L155.959 17.0594L155.877 16.8914H154.638L154.556 17.0594L155.216 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M144.164 18.528L143.462 19.2422L144.164 19.9564L144.865 19.2422L144.164 18.528Z'
                fill='#1A1A1A'
            />
            <path
                d='M134.173 17.4942L133.472 18.2087L134.174 18.9226L134.876 18.208L134.173 17.4942Z'
                fill='#1A1A1A'
            />
            <path
                d='M131.956 17.4736L131.254 18.1877L131.956 18.9019L132.657 18.1878L131.956 17.4736Z'
                fill='#1A1A1A'
            />
            <path
                d='M129.655 17.4539L128.953 18.1677L129.654 18.8823L130.356 18.1685L129.655 17.4539Z'
                fill='#1A1A1A'
            />
            <path
                d='M156.346 19.7263L155.645 20.4404L156.346 21.1546L157.048 20.4405L156.346 19.7263Z'
                fill='#1A1A1A'
            />
            <path
                d='M154.138 19.7144L153.436 20.4282L154.137 21.1428L154.839 20.429L154.138 19.7144Z'
                fill='#1A1A1A'
            />
            <path
                d='M159.676 17.7317L160.419 17.0594L160.254 16.8914H159.099L158.933 17.0594L159.676 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M148.554 18.5141L147.853 19.2283L148.554 19.9425L149.256 19.2283L148.554 18.5141Z'
                fill='#1A1A1A'
            />
            <path
                d='M145.264 17.5196L144.562 18.2329L145.262 18.948L145.965 18.2346L145.264 17.5196Z'
                fill='#1A1A1A'
            />
            <path
                d='M139.667 18.5669L138.966 19.2815L139.668 19.9953L140.369 19.2807L139.667 18.5669Z'
                fill='#1A1A1A'
            />
            <path
                d='M150.839 17.7317L151.5 17.0594L151.417 16.8914H150.179L150.096 17.0594L150.839 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M148.61 17.7317L149.354 17.0594L149.188 16.8914H148.033L147.867 17.0594L148.61 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M143.058 17.4375L142.356 18.1516L143.058 18.8658L143.76 18.1516L143.058 17.4375Z'
                fill='#1A1A1A'
            />
            <path
                d='M137.391 18.5269L136.689 19.2407L137.391 19.9553L138.093 19.2415L137.391 18.5269Z'
                fill='#1A1A1A'
            />
            <path
                d='M140.821 17.443L140.119 18.1572L140.821 18.8714L141.523 18.1572L140.821 17.443Z'
                fill='#1A1A1A'
            />
            <path
                d='M145.244 19.7631L144.542 20.4773L145.244 21.1915L145.945 20.4773L145.244 19.7631Z'
                fill='#1A1A1A'
            />
            <path
                d='M141.925 18.5363L141.224 19.2505L141.925 19.9647L142.627 19.2505L141.925 18.5363Z'
                fill='#1A1A1A'
            />
            <path
                d='M135.275 18.4997L134.573 19.2139L135.275 19.9281L135.977 19.2139L135.275 18.4997Z'
                fill='#1A1A1A'
            />
            <path
                d='M146.381 17.7317L147.124 17.0594L146.959 16.8914H145.803L145.638 17.0594L146.381 17.7317Z'
                fill='#1A1A1A'
            />
            <path
                d='M146.796 20.4216L147.456 21.1779L148.2 20.4216L147.456 19.6653L146.796 20.4216Z'
                fill='#1A1A1A'
            />
            <path
                d='M143.067 19.7124L142.365 20.4265L143.067 21.1407L143.769 20.4265L143.067 19.7124Z'
                fill='#1A1A1A'
            />
            <path
                d='M129.787 21.1765L129.044 20.4202L128.714 20.7563V20.9244L129.374 21.5126L129.787 21.0925V21.1765Z'
                fill='#262626'
            />
            <path
                d='M128.298 19.6653C128.298 19.6653 128.298 20.0033 128.381 20.1724L128.629 19.9188L128.298 19.5808V19.6653Z'
                fill='#262626'
            />
            <path
                d='M129.788 21.8512C129.788 21.8512 130.366 22.0198 130.613 22.0198L130.118 21.5139L129.788 21.8512Z'
                fill='#262626'
            />
            <path
                d='M131.936 21.9355L132.101 22.1042H132.926L132.348 21.5139L131.936 21.9355Z'
                fill='#262626'
            />
            <path
                d='M131.269 20.4508L130.859 20.8674L131.561 21.5816L131.97 21.165L131.269 20.4508Z'
                fill='#262626'
            />
            <path
                d='M129.085 18.122L128.675 18.5386L129.377 19.2528L129.786 18.8362L129.085 18.122Z'
                fill='#262626'
            />
            <path
                d='M130.141 19.2452L129.731 19.6614L130.432 20.3763L130.842 19.9601L130.141 19.2452Z'
                fill='#262626'
            />
            <path
                d='M131.26 18.1737L130.851 18.5903L131.553 19.3045L131.962 18.8879L131.26 18.1737Z'
                fill='#262626'
            />
            <path
                d='M133.506 20.4425L133.097 20.8591L133.798 21.5733L134.208 21.1567L133.506 20.4425Z'
                fill='#262626'
            />
            <path
                d='M134.165 21.9355L134.33 22.1042H135.156L134.578 21.5139L134.165 21.9355Z'
                fill='#262626'
            />
            <path
                d='M132.361 19.2653L131.952 19.6816L132.653 20.3962L133.062 19.9798L132.361 19.2653Z'
                fill='#262626'
            />
            <path
                d='M130.449 18.152L130.862 17.7319L130.202 17.0596L129.871 17.3957L130.614 18.152H130.449Z'
                fill='#262626'
            />
            <path
                d='M136.396 21.9355L136.561 22.1042H137.386L136.808 21.5139L136.396 21.9355Z'
                fill='#262626'
            />
            <path
                d='M133.477 18.1921L133.068 18.6089L133.77 19.3227L134.179 18.9059L133.477 18.1921Z'
                fill='#262626'
            />
            <path
                d='M135.744 20.4342L135.334 20.8508L136.036 21.565L136.445 21.1484L135.744 20.4342Z'
                fill='#262626'
            />
            <path
                d='M134.619 19.2826L134.209 19.6985L134.909 20.4139L135.319 19.998L134.619 19.2826Z'
                fill='#262626'
            />
            <path
                d='M132.337 17.0143L131.928 17.4309L132.629 18.1451L133.039 17.7285L132.337 17.0143Z'
                fill='#262626'
            />
            <path
                d='M131.689 16.8914H131.44L131.606 17.06L131.689 16.8914Z'
                fill='#262626'
            />
            <path
                d='M138.621 21.9355L138.786 22.1042H139.529L139.033 21.5139L138.621 21.9355Z'
                fill='#262626'
            />
            <path
                d='M134.634 17.0324L134.225 17.4492L134.927 18.163L135.336 17.7462L134.634 17.0324Z'
                fill='#262626'
            />
            <path
                d='M137.981 20.4262L137.572 20.8428L138.273 21.557L138.683 21.1404L137.981 20.4262Z'
                fill='#262626'
            />
            <path
                d='M135.735 18.1593L135.326 18.5759L136.028 19.2901L136.437 18.8735L135.735 18.1593Z'
                fill='#262626'
            />
            <path
                d='M136.837 19.2508L136.427 19.6672L137.129 20.3818L137.538 19.9654L136.837 19.2508Z'
                fill='#262626'
            />
            <path
                d='M133.918 16.8914H133.669L133.835 17.06L133.918 16.8914Z'
                fill='#262626'
            />
            <path
                d='M136.808 16.9975L136.398 17.4141L137.1 18.1283L137.509 17.7116L136.808 16.9975Z'
                fill='#262626'
            />
            <path
                d='M140.215 20.4198L139.806 20.8364L140.507 21.5506L140.917 21.134L140.215 20.4198Z'
                fill='#262626'
            />
            <path
                d='M139.018 19.3554L138.608 19.7715L139.309 20.4865L139.719 20.0705L139.018 19.3554Z'
                fill='#262626'
            />
            <path
                d='M140.849 21.9355L141.014 22.1042H141.757L141.261 21.5139L140.849 21.9355Z'
                fill='#262626'
            />
            <path
                d='M136.148 16.8914H135.899L135.982 17.06L136.148 16.8914Z'
                fill='#262626'
            />
            <path
                d='M137.949 18.1752L137.54 18.592L138.242 19.3059L138.651 18.889L137.949 18.1752Z'
                fill='#262626'
            />
            <path
                d='M139.009 17.0248L138.6 17.4412L139.301 18.1557L139.71 17.7393L139.009 17.0248Z'
                fill='#262626'
            />
            <path
                d='M142.371 20.4115L141.962 20.8281L142.664 21.5423L143.073 21.1257L142.371 20.4115Z'
                fill='#262626'
            />
            <path
                d='M138.376 16.8914H138.128L138.211 17.06L138.376 16.8914Z'
                fill='#262626'
            />
            <path
                d='M141.17 19.2958L140.761 19.7122L141.462 20.4267L141.872 20.0103L141.17 19.2958Z'
                fill='#262626'
            />
            <path
                d='M140.126 18.1427L139.716 18.5593L140.418 19.2735L140.827 18.8569L140.126 18.1427Z'
                fill='#262626'
            />
            <path
                d='M143.078 21.9355L143.243 22.1042H143.986L143.408 21.5139L143.078 21.9355Z'
                fill='#262626'
            />
            <path
                d='M143.408 19.2894L142.999 19.7058L143.7 20.4204L144.109 20.004L143.408 19.2894Z'
                fill='#262626'
            />
            <path
                d='M141.222 17.0429L140.812 17.4595L141.514 18.1737L141.923 17.7571L141.222 17.0429Z'
                fill='#262626'
            />
            <path
                d='M144.568 20.4202L144.155 20.8403L144.898 21.5126L145.311 21.1765L144.568 20.4202Z'
                fill='#262626'
            />
            <path
                d='M145.308 21.9355L145.391 22.1042H146.216L145.638 21.5139L145.308 21.9355Z'
                fill='#262626'
            />
            <path
                d='M140.606 16.8914H140.358L140.441 17.06L140.606 16.8914Z'
                fill='#262626'
            />
            <path
                d='M142.363 18.1347L141.954 18.5513L142.655 19.2655L143.065 18.8489L142.363 18.1347Z'
                fill='#262626'
            />
            <path
                d='M146.786 20.455L146.376 20.8716L147.078 21.5858L147.487 21.1692L146.786 20.455Z'
                fill='#262626'
            />
            <path
                d='M145.705 19.3039L145.296 19.7205L145.998 20.4346L146.407 20.018L145.705 19.3039Z'
                fill='#262626'
            />
            <path
                d='M147.457 21.9355L147.622 22.1042H148.448L147.87 21.5139L147.457 21.9355Z'
                fill='#262626'
            />
            <path
                d='M142.754 16.8914H142.587L142.67 17.06L142.754 16.8914Z'
                fill='#262626'
            />
            <path
                d='M143.435 17.0573L143.026 17.4741L143.728 18.1879L144.137 17.7711L143.435 17.0573Z'
                fill='#262626'
            />
            <path
                d='M145.311 18.9079L144.568 18.1516L144.155 18.5718L144.898 19.3281L145.311 18.9079Z'
                fill='#262626'
            />
            <path
                d='M146.801 18.1576L146.392 18.574L147.093 19.2885L147.502 18.8722L146.801 18.1576Z'
                fill='#262626'
            />
            <path
                d='M147.882 19.1913L147.473 19.6077L148.174 20.3222L148.583 19.9058L147.882 19.1913Z'
                fill='#262626'
            />
            <path
                d='M144.98 16.8914H144.732L144.897 17.06L144.98 16.8914Z'
                fill='#262626'
            />
            <path
                d='M145.697 17.0263L145.288 17.4429L145.99 18.1571L146.399 17.7405L145.697 17.0263Z'
                fill='#262626'
            />
            <path
                d='M149.686 21.9355L149.851 22.1042H150.676L150.098 21.5139L149.686 21.9355Z'
                fill='#262626'
            />
            <path
                d='M149.023 20.4488L148.613 20.8655L149.315 21.5797L149.724 21.163L149.023 20.4488Z'
                fill='#262626'
            />
            <path
                d='M147.209 16.8914H146.961L147.127 17.06L147.209 16.8914Z'
                fill='#262626'
            />
            <path
                d='M149.014 18.1712L148.604 18.5879L149.306 19.3021L149.716 18.8854L149.014 18.1712Z'
                fill='#262626'
            />
            <path
                d='M150.119 19.1849L149.71 19.6013L150.411 20.3159L150.821 19.8995L150.119 19.1849Z'
                fill='#262626'
            />
            <path
                d='M151.915 21.9355L152.08 22.1042H152.906L152.328 21.5139L151.915 21.9355Z'
                fill='#262626'
            />
            <path
                d='M147.935 17.0204L147.525 17.437L148.227 18.1512L148.636 17.7346L147.935 17.0204Z'
                fill='#262626'
            />
            <path
                d='M151.261 20.4408L150.852 20.8574L151.553 21.5716L151.963 21.155L151.261 20.4408Z'
                fill='#262626'
            />
            <path
                d='M154.145 21.9355L154.31 22.1042H155.053L154.558 21.5139L154.145 21.9355Z'
                fill='#262626'
            />
            <path
                d='M152.309 19.2961L151.899 19.7122L152.6 20.4272L153.01 20.0111L152.309 19.2961Z'
                fill='#262626'
            />
            <path
                d='M149.439 16.8914H149.19L149.356 17.06L149.439 16.8914Z'
                fill='#262626'
            />
            <path
                d='M151.916 18.9079L151.173 18.1516L150.843 18.5718L151.503 19.3281L151.916 18.9079Z'
                fill='#262626'
            />
            <path
                d='M150.089 17.0121L149.679 17.4287L150.381 18.1429L150.79 17.7263L150.089 17.0121Z'
                fill='#262626'
            />
            <path
                d='M153.466 20.3958L153.056 20.812L153.757 21.527L154.167 21.1108L153.466 20.3958Z'
                fill='#262626'
            />
            <path
                d='M156.374 21.9355L156.539 22.1042H157.282L156.787 21.5139L156.374 21.9355Z'
                fill='#262626'
            />
            <path
                d='M151.668 16.8914H151.42L151.503 17.06L151.668 16.8914Z'
                fill='#262626'
            />
            <path
                d='M153.433 18.1366L153.024 18.553L153.725 19.2675L154.135 18.8512L153.433 18.1366Z'
                fill='#262626'
            />
            <path
                d='M154.566 19.351L154.157 19.7678L154.859 20.4816L155.268 20.0648L154.566 19.351Z'
                fill='#262626'
            />
            <path
                d='M155.63 20.4202L155.217 20.8403L155.96 21.5126L156.373 21.1765L155.63 20.4202Z'
                fill='#262626'
            />
            <path
                d='M152.269 17.0636L151.859 17.4802L152.561 18.1944L152.97 17.7778L152.269 17.0636Z'
                fill='#262626'
            />
            <path
                d='M158.604 21.9355L158.769 22.1042H159.512L158.934 21.5139L158.604 21.9355Z'
                fill='#262626'
            />
            <path
                d='M153.898 16.8914H153.649L153.732 17.06L153.898 16.8914Z'
                fill='#262626'
            />
            <path
                d='M154.501 17.0551L154.092 17.4717L154.793 18.1859L155.203 17.7693L154.501 17.0551Z'
                fill='#262626'
            />
            <path
                d='M156.374 19.665L157.117 20.4213L157.447 20.0011L156.787 19.3289L156.374 19.665Z'
                fill='#262626'
            />
            <path
                d='M157.859 20.4202L157.446 20.8403L158.189 21.5126L158.602 21.1765L157.859 20.4202Z'
                fill='#262626'
            />
            <path
                d='M156.373 18.9079L155.63 18.1516L155.217 18.5718L155.96 19.3281L156.373 18.9079Z'
                fill='#262626'
            />
            <path
                d='M157.447 17.7319L156.787 17.0596L156.374 17.3957L157.117 18.152L157.447 17.7319Z'
                fill='#262626'
            />
            <path
                d='M160.121 20.4371L159.711 20.8533L160.412 21.5682L160.822 21.152L160.121 20.4371Z'
                fill='#262626'
            />
            <path
                d='M160.833 21.9355L160.915 22.1042H161.741L161.163 21.5139L160.833 21.9355Z'
                fill='#262626'
            />
            <path
                d='M156.045 16.8914H155.878L155.961 17.06L156.045 16.8914Z'
                fill='#262626'
            />
            <path
                d='M158.932 19.2159L158.522 19.6323L159.224 20.3469L159.633 19.9305L158.932 19.2159Z'
                fill='#262626'
            />
            <path
                d='M157.921 18.1845L157.511 18.6006L158.212 19.3157L158.621 18.8996L157.921 18.1845Z'
                fill='#262626'
            />
            <path
                d='M159.677 17.7319L158.934 17.0596L158.603 17.3957L159.264 18.152L159.677 17.7319Z'
                fill='#262626'
            />
            <path
                d='M158.273 16.8914H158.107L158.19 17.06L158.273 16.8914Z'
                fill='#262626'
            />
            <path
                d='M162.318 20.4202L161.905 20.8403L162.648 21.5126L162.978 21.1765L162.318 20.4202Z'
                fill='#262626'
            />
            <path
                d='M160.057 18.1923L159.648 18.6089L160.35 19.3231L160.759 18.9065L160.057 18.1923Z'
                fill='#262626'
            />
            <path
                d='M161.263 19.2741L160.853 19.6902L161.554 20.4052L161.964 19.9892L161.263 19.2741Z'
                fill='#262626'
            />
            <path
                d='M162.978 21.9355L163.143 22.1042H163.969L163.391 21.5139L162.978 21.9355Z'
                fill='#262626'
            />
            <path
                d='M164.54 20.453L164.131 20.8696L164.833 21.5838L165.242 21.1672L164.54 20.453Z'
                fill='#262626'
            />
            <path
                d='M162.978 18.9079L162.318 18.1516L161.905 18.5718L162.648 19.3281L162.978 18.9079Z'
                fill='#262626'
            />
            <path
                d='M165.207 21.9355L165.372 22.1042H166.197L165.619 21.5139L165.207 21.9355Z'
                fill='#262626'
            />
            <path
                d='M161.214 17.0326L160.805 17.4492L161.507 18.1634L161.916 17.7468L161.214 17.0326Z'
                fill='#262626'
            />
            <path
                d='M162.978 19.665L163.721 20.4213L164.134 20.0011L163.391 19.3289L162.978 19.665Z'
                fill='#262626'
            />
            <path
                d='M160.501 16.8914H160.253L160.418 17.06L160.501 16.8914Z'
                fill='#262626'
            />
            <path
                d='M163.415 16.9877L163.005 17.4038L163.706 18.1189L164.116 17.7027L163.415 16.9877Z'
                fill='#262626'
            />
            <path
                d='M167.436 21.9355L167.601 22.1042H168.344L167.849 21.5139L167.436 21.9355Z'
                fill='#262626'
            />
            <path
                d='M162.731 16.8914H162.482L162.648 17.06L162.731 16.8914Z'
                fill='#262626'
            />
            <path
                d='M164.576 18.227L164.166 18.6431L164.867 19.3581L165.277 18.942L164.576 18.227Z'
                fill='#262626'
            />
            <path
                d='M166.694 20.4202L166.364 20.8403L167.024 21.5126L167.437 21.1765L166.694 20.4202Z'
                fill='#262626'
            />
            <path
                d='M165.207 19.665L165.95 20.4213L166.362 20.0011L165.619 19.3289L165.207 19.665Z'
                fill='#262626'
            />
            <path
                d='M167.437 18.9079L166.694 18.1516L166.364 18.5718L167.024 19.3281L167.437 18.9079Z'
                fill='#262626'
            />
            <path
                d='M168.923 20.4202L168.593 20.8403L169.254 21.5126L169.667 21.1765L168.923 20.4202Z'
                fill='#262626'
            />
            <path
                d='M165.64 16.9686L165.231 17.3857L165.933 18.0991L166.342 17.6819L165.64 16.9686Z'
                fill='#262626'
            />
            <path
                d='M169.666 21.9355L169.831 22.1042H170.574L170.078 21.5139L169.666 21.9355Z'
                fill='#262626'
            />
            <path
                d='M164.959 16.8914H164.71L164.876 17.06L164.959 16.8914Z'
                fill='#262626'
            />
            <path
                d='M167.436 19.665L168.179 20.4213L168.592 20.0011L167.849 19.3289L167.436 19.665Z'
                fill='#262626'
            />
            <path
                d='M168.592 17.7319L167.849 17.0596L167.436 17.3957L168.179 18.152L168.592 17.7319Z'
                fill='#262626'
            />
            <path
                d='M171.199 20.3923L170.791 20.8098L171.494 21.5225L171.903 21.105L171.199 20.3923Z'
                fill='#262626'
            />
            <path
                d='M169.666 19.665L170.409 20.4213L170.739 20.0011L170.078 19.3289L169.666 19.665Z'
                fill='#262626'
            />
            <path
                d='M167.188 16.8914H166.94L167.023 17.06L167.188 16.8914Z'
                fill='#262626'
            />
            <path
                d='M171.895 21.9355L172.06 22.1042H172.803L172.308 21.5139L171.895 21.9355Z'
                fill='#262626'
            />
            <path
                d='M169.667 18.9079L168.923 18.1516L168.593 18.5718L169.254 19.3281L169.667 18.9079Z'
                fill='#262626'
            />
            <path
                d='M169.418 16.8914H169.17L169.252 17.06L169.418 16.8914Z'
                fill='#262626'
            />
            <path
                d='M170.739 17.7319L170.078 17.0596L169.666 17.3957L170.409 18.152L170.739 17.7319Z'
                fill='#262626'
            />
            <path
                d='M171.241 18.153L170.832 18.5696L171.533 19.2838L171.943 18.8672L171.241 18.153Z'
                fill='#262626'
            />
            <path
                d='M173.417 20.4443L173.008 20.8613L173.711 21.5748L174.12 21.1577L173.417 20.4443Z'
                fill='#262626'
            />
            <path
                d='M174.123 21.9349C174.123 21.9349 174.535 21.9349 174.783 21.9349L174.453 21.5977L174.04 22.0193L174.123 21.9349Z'
                fill='#262626'
            />
            <path
                d='M172.313 19.3405L171.904 19.7573L172.606 20.4711L173.015 20.0543L172.313 19.3405Z'
                fill='#262626'
            />
            <path
                d='M171.566 16.8914H171.399L171.482 17.06L171.566 16.8914Z'
                fill='#262626'
            />
            <path
                d='M175.197 20.8403L175.61 21.2605C175.61 21.2605 175.857 20.9244 175.94 20.7563L175.61 20.4202L175.197 20.8403Z'
                fill='#262626'
            />
            <path
                d='M172.304 17.0654L171.895 17.4822L172.597 18.196L173.006 17.7792L172.304 17.0654Z'
                fill='#262626'
            />
            <path
                d='M173.445 18.1571L173.036 18.574L173.738 19.2878L174.147 18.871L173.445 18.1571Z'
                fill='#262626'
            />
            <path
                d='M174.125 19.665L174.785 20.4213L175.198 20.0011L174.455 19.3289L174.125 19.665Z'
                fill='#262626'
            />
            <path
                d='M175.197 18.5713L175.94 19.3276L176.188 19.0755C176.188 19.0755 176.188 18.9074 176.188 18.8234L175.61 18.2351L175.197 18.6553V18.5713Z'
                fill='#262626'
            />
            <path
                d='M174.784 18.152L175.197 17.7319L174.536 17.0596L174.206 17.3957L174.949 18.152H174.784Z'
                fill='#262626'
            />
            <path
                d='M173.795 16.8914H173.629L173.712 17.06L173.795 16.8914Z'
                fill='#262626'
            />
            <path
                d='M129.788 17.3959L129.623 17.2278C129.623 17.2278 129.045 17.648 128.88 17.9001L129.045 18.0681L129.788 17.3118V17.3959Z'
                fill='#333333'
            />
            <path
                d='M128.547 18.4871C128.547 18.4871 128.547 18.7406 128.464 18.8251L128.713 18.5716H128.63L128.547 18.4871Z'
                fill='#333333'
            />
            <path
                d='M129.046 18.1522L128.881 17.9832C128.881 17.9832 128.633 18.3212 128.55 18.4902H128.633L129.046 18.2367V18.1522Z'
                fill='#333333'
            />
            <path
                d='M130.119 17.0596C130.119 17.0596 129.788 17.1441 129.622 17.3131L129.788 17.4821L130.119 17.1441V17.0596Z'
                fill='#333333'
            />
            <path
                d='M132.515 16.8914H132.266L132.349 17.06L132.515 16.8914Z'
                fill='#333333'
            />
            <path
                d='M130.464 18.2492L129.763 18.9634L130.172 19.38L130.874 18.6658L130.464 18.2492Z'
                fill='#333333'
            />
            <path
                d='M131.58 17.0604L130.879 17.7754L131.289 18.1916L131.99 17.4766L131.58 17.0604Z'
                fill='#333333'
            />
            <path
                d='M129.372 19.3174L128.671 20.032L129.08 20.4484L129.782 19.7338L129.372 19.3174Z'
                fill='#333333'
            />
            <path
                d='M128.631 20.0022L128.384 20.2543C128.384 20.2543 128.549 20.6745 128.631 20.8426L128.962 20.5064L128.549 20.0862L128.631 20.0022Z'
                fill='#333333'
            />
            <path
                d='M129.715 18.8884L129.304 19.3062L129.715 19.7239L130.125 19.3062L129.715 18.8884Z'
                fill='#333333'
            />
            <path
                d='M130.928 17.7026L130.517 18.1204L130.928 18.5381L131.338 18.1204L130.928 17.7026Z'
                fill='#333333'
            />
            <path
                d='M131.936 17.3973L132.349 17.06L132.266 16.8914H131.688L131.606 17.06L131.936 17.3973Z'
                fill='#333333'
            />
            <path
                d='M132.702 18.2409L132 18.9551L132.41 19.3717L133.111 18.6575L132.702 18.2409Z'
                fill='#333333'
            />
            <path
                d='M131.63 19.2819L130.928 19.9961L131.338 20.4127L132.039 19.6985L131.63 19.2819Z'
                fill='#333333'
            />
            <path
                d='M134.658 16.8914H134.492L134.575 17.06L134.658 16.8914Z'
                fill='#333333'
            />
            <path
                d='M133.718 17.0573L133.017 17.7715L133.426 18.1881L134.128 17.4739L133.718 17.0573Z'
                fill='#333333'
            />
            <path
                d='M130.412 20.4657L129.71 21.1799L130.12 21.5965L130.821 20.8823L130.412 20.4657Z'
                fill='#333333'
            />
            <path
                d='M129.376 21.5139V21.6H129.461L129.376 21.5139Z'
                fill='#333333'
            />
            <path
                d='M129.789 21.1772L129.376 21.5969C129.376 21.5969 129.624 21.8487 129.789 21.9326L130.12 21.5969L129.707 21.1772H129.789Z'
                fill='#333333'
            />
            <path
                d='M130.935 19.9797L130.525 20.3975L130.935 20.8152L131.346 20.3975L130.935 19.9797Z'
                fill='#333333'
            />
            <path
                d='M131.988 18.8249L131.578 19.2422L131.988 19.6604L132.398 19.2431L131.988 18.8249Z'
                fill='#333333'
            />
            <path
                d='M133.121 17.7253L132.71 18.1426L133.12 18.5608L133.531 18.1435L133.121 17.7253Z'
                fill='#333333'
            />
            <path
                d='M134.165 17.3973L134.578 17.06L134.496 16.8914H133.918L133.835 17.06L134.165 17.3973Z'
                fill='#333333'
            />
            <path
                d='M135.321 18.5718L134.908 18.1516L134.165 18.9079L134.578 19.3281L135.321 18.5718Z'
                fill='#333333'
            />
            <path
                d='M135.995 17.1019L135.294 17.8169L135.704 18.2331L136.405 17.5181L135.995 17.1019Z'
                fill='#333333'
            />
            <path
                d='M132.649 20.4592L131.948 21.1733L132.357 21.5899L133.059 20.8758L132.649 20.4592Z'
                fill='#333333'
            />
            <path
                d='M131.602 21.5139L131.024 22.1042H131.85L131.933 21.9355L131.602 21.5139Z'
                fill='#333333'
            />
            <path
                d='M136.887 16.8914H136.721L136.804 17.06L136.887 16.8914Z'
                fill='#333333'
            />
            <path
                d='M133.726 19.3324L133.024 20.0466L133.434 20.4632L134.135 19.749L133.726 19.3324Z'
                fill='#333333'
            />
            <path
                d='M132.003 21.1238L131.593 21.542L132.004 21.9593L132.414 21.5411L132.003 21.1238Z'
                fill='#333333'
            />
            <path
                d='M133.172 19.9714L132.762 20.3892L133.172 20.8069L133.583 20.3892L133.172 19.9714Z'
                fill='#333333'
            />
            <path
                d='M134.165 19.6638L134.578 19.3281L134.165 18.9084L133.835 19.3281L134.165 19.6638Z'
                fill='#333333'
            />
            <path
                d='M135.342 17.748L134.931 18.1658L135.342 18.5835L135.752 18.1658L135.342 17.748Z'
                fill='#333333'
            />
            <path
                d='M136.392 17.3973L136.805 17.06L136.723 16.8914H136.145L135.979 17.06L136.392 17.3973Z'
                fill='#333333'
            />
            <path
                d='M139.118 16.8914H138.87L139.036 17.06L139.118 16.8914Z'
                fill='#333333'
            />
            <path
                d='M134.843 20.4003L134.141 21.1138L134.55 21.5308L135.252 20.8174L134.843 20.4003Z'
                fill='#333333'
            />
            <path
                d='M135.959 19.3258L135.257 20.04L135.667 20.4566L136.368 19.7424L135.959 19.3258Z'
                fill='#333333'
            />
            <path
                d='M133.831 21.5139L133.253 22.1042H133.997L134.162 21.9355L133.831 21.5139Z'
                fill='#333333'
            />
            <path
                d='M138.621 17.3957L138.208 17.0596L137.548 17.7319L137.878 18.152L138.621 17.3957Z'
                fill='#333333'
            />
            <path
                d='M137.551 18.5718L137.139 18.1516L136.396 18.9079L136.808 19.3281L137.551 18.5718Z'
                fill='#333333'
            />
            <path
                d='M133.835 21.513L134.165 21.9326L134.578 21.513L134.165 21.1772L133.835 21.513Z'
                fill='#333333'
            />
            <path
                d='M135.305 20.058L134.895 20.4753L135.305 20.8935L135.715 20.4762L135.305 20.058Z'
                fill='#333333'
            />
            <path
                d='M136.392 19.6638L136.805 19.3281L136.392 18.9084L135.979 19.3281L136.392 19.6638Z'
                fill='#333333'
            />
            <path
                d='M137.494 17.7395L137.083 18.1572L137.494 18.575L137.904 18.1572L137.494 17.7395Z'
                fill='#333333'
            />
            <path
                d='M138.622 17.3973L139.035 17.06L138.869 16.8914H138.374L138.209 17.06L138.622 17.3973Z'
                fill='#333333'
            />
            <path
                d='M141.348 16.8914H141.099L141.265 17.06L141.348 16.8914Z'
                fill='#333333'
            />
            <path
                d='M138.216 19.2943L137.514 20.0081L137.923 20.4249L138.625 19.7111L138.216 19.2943Z'
                fill='#333333'
            />
            <path
                d='M136.396 21.1765L136.808 21.5126L137.551 20.8403L137.139 20.4202L136.396 21.1765Z'
                fill='#333333'
            />
            <path
                d='M135.978 21.5139L135.483 22.1042H136.226L136.391 21.9355L135.978 21.5139Z'
                fill='#333333'
            />
            <path
                d='M139.411 18.2189L138.709 18.9331L139.118 19.3497L139.82 18.6355L139.411 18.2189Z'
                fill='#333333'
            />
            <path
                d='M140.852 17.3957L140.439 17.0596L139.696 17.7319L140.109 18.152L140.852 17.3957Z'
                fill='#333333'
            />
            <path
                d='M135.979 21.513L136.392 21.9326L136.805 21.513L136.392 21.1772L135.979 21.513Z'
                fill='#333333'
            />
            <path
                d='M137.547 20.0652L137.137 20.4834L137.548 20.9007L137.958 20.4825L137.547 20.0652Z'
                fill='#333333'
            />
            <path
                d='M138.622 19.6638L139.035 19.3281L138.622 18.9084L138.209 19.3281L138.622 19.6638Z'
                fill='#333333'
            />
            <path
                d='M139.732 17.7314L139.322 18.1492L139.732 18.5669L140.143 18.1492L139.732 17.7314Z'
                fill='#333333'
            />
            <path
                d='M140.851 17.3973L141.264 17.06L141.099 16.8914H140.604L140.438 17.06L140.851 17.3973Z'
                fill='#333333'
            />
            <path
                d='M138.208 21.5139L137.712 22.1042H138.456L138.621 21.9355L138.208 21.5139Z'
                fill='#333333'
            />
            <path
                d='M139.323 20.3876L138.621 21.1011L139.029 21.5181L139.732 20.8047L139.323 20.3876Z'
                fill='#333333'
            />
            <path
                d='M143.577 16.8914H143.329L143.411 17.06L143.577 16.8914Z'
                fill='#333333'
            />
            <path
                d='M141.508 18.27L140.806 18.9841L141.215 19.4007L141.917 18.6866L141.508 18.27Z'
                fill='#333333'
            />
            <path
                d='M140.413 19.334L139.712 20.0486L140.121 20.465L140.823 19.7504L140.413 19.334Z'
                fill='#333333'
            />
            <path
                d='M142.614 17.0729L141.914 17.7878L142.323 18.204L143.024 17.4891L142.614 17.0729Z'
                fill='#333333'
            />
            <path
                d='M138.209 21.513L138.622 21.9326L139.035 21.513L138.622 21.1772L138.209 21.513Z'
                fill='#333333'
            />
            <path
                d='M139.739 20.0088L139.329 20.4265L139.739 20.8443L140.149 20.4265L139.739 20.0088Z'
                fill='#333333'
            />
            <path
                d='M140.857 18.8029L140.446 19.2202L140.856 19.6384L141.267 19.221L140.857 18.8029Z'
                fill='#333333'
            />
            <path
                d='M141.969 17.7231L141.558 18.1409L141.969 18.5586L142.379 18.1409L141.969 17.7231Z'
                fill='#333333'
            />
            <path
                d='M143.08 17.3973L143.41 17.06L143.328 16.8914H142.75L142.667 17.06L143.08 17.3973Z'
                fill='#333333'
            />
            <path
                d='M145.807 16.8914H145.558L145.641 17.06L145.807 16.8914Z'
                fill='#333333'
            />
            <path
                d='M143.743 18.2614L143.041 18.9756L143.45 19.3922L144.152 18.678L143.743 18.2614Z'
                fill='#333333'
            />
            <path
                d='M141.458 20.4035L140.756 21.1177L141.166 21.5343L141.867 20.8201L141.458 20.4035Z'
                fill='#333333'
            />
            <path
                d='M144.866 17.058L144.163 17.7715L144.572 18.1885L145.274 17.4751L144.866 17.058Z'
                fill='#333333'
            />
            <path
                d='M140.439 21.5139L139.861 22.1042H140.687L140.852 21.9355L140.439 21.5139Z'
                fill='#333333'
            />
            <path
                d='M142.671 19.3015L141.97 20.0156L142.379 20.4322L143.081 19.7181L142.671 19.3015Z'
                fill='#333333'
            />
            <path
                d='M140.438 21.513L140.851 21.9326L141.264 21.513L140.851 21.1772L140.438 21.513Z'
                fill='#333333'
            />
            <path
                d='M141.977 20.0007L141.567 20.4185L141.977 20.8362L142.388 20.4185L141.977 20.0007Z'
                fill='#333333'
            />
            <path
                d='M143.08 19.6638L143.41 19.3281L143.08 18.9084L142.667 19.3281L143.08 19.6638Z'
                fill='#333333'
            />
            <path
                d='M144.243 17.7613L143.833 18.1794L144.243 18.5968L144.653 18.1786L144.243 17.7613Z'
                fill='#333333'
            />
            <path
                d='M145.31 17.3973L145.64 17.06L145.558 16.8914H144.98L144.897 17.06L145.31 17.3973Z'
                fill='#333333'
            />
            <path
                d='M148.036 16.8914H147.788L147.87 17.06L148.036 16.8914Z'
                fill='#333333'
            />
            <path
                d='M144.909 19.2953L144.208 20.0095L144.617 20.4261L145.319 19.7119L144.909 19.2953Z'
                fill='#333333'
            />
            <path
                d='M147.138 17.0097L146.437 17.7239L146.846 18.1405L147.547 17.4263L147.138 17.0097Z'
                fill='#333333'
            />
            <path
                d='M146.381 18.5718L145.969 18.1516L145.308 18.9079L145.638 19.3281L146.381 18.5718Z'
                fill='#333333'
            />
            <path
                d='M143.741 20.435L143.038 21.1484L143.447 21.5655L144.149 20.852L143.741 20.435Z'
                fill='#333333'
            />
            <path
                d='M142.669 21.5139L142.091 22.1042H142.917L143.082 21.9355L142.669 21.5139Z'
                fill='#333333'
            />
            <path
                d='M142.667 21.513L143.08 21.9326L143.41 21.513L143.08 21.1772L142.667 21.513Z'
                fill='#333333'
            />
            <path
                d='M144.214 19.9924L143.804 20.4102L144.214 20.8279L144.625 20.4102L144.214 19.9924Z'
                fill='#333333'
            />
            <path
                d='M145.31 19.6638L145.641 19.3281L145.31 18.9084L144.897 19.3281L145.31 19.6638Z'
                fill='#333333'
            />
            <path
                d='M146.445 17.709L146.034 18.1267L146.445 18.5445L146.855 18.1267L146.445 17.709Z'
                fill='#333333'
            />
            <path
                d='M147.456 17.3973L147.869 17.06L147.786 16.8914H147.209L147.126 17.06L147.456 17.3973Z'
                fill='#333333'
            />
            <path
                d='M144.898 21.5139L144.32 22.1042H145.146L145.311 21.9355L144.898 21.5139Z'
                fill='#333333'
            />
            <path
                d='M145.929 20.3869L145.228 21.1011L145.637 21.5177L146.338 20.8035L145.929 20.3869Z'
                fill='#333333'
            />
            <path
                d='M148.219 18.2472L147.517 18.9614L147.926 19.378L148.628 18.6638L148.219 18.2472Z'
                fill='#333333'
            />
            <path
                d='M149.262 17.1039L148.562 17.8188L148.971 18.235L149.672 17.5201L149.262 17.1039Z'
                fill='#333333'
            />
            <path
                d='M147.113 19.2518L146.413 19.9668L146.822 20.3829L147.523 19.6679L147.113 19.2518Z'
                fill='#333333'
            />
            <path
                d='M150.183 16.8914H150.016L150.1 17.06L150.183 16.8914Z'
                fill='#333333'
            />
            <path
                d='M144.897 21.513L145.31 21.9326L145.641 21.513L145.31 21.1772L144.897 21.513Z'
                fill='#333333'
            />
            <path
                d='M146.453 19.9861L146.042 20.4038L146.453 20.8216L146.863 20.4038L146.453 19.9861Z'
                fill='#333333'
            />
            <path
                d='M147.517 18.8434L147.106 19.2607L147.516 19.6789L147.927 19.2616L147.517 18.8434Z'
                fill='#333333'
            />
            <path
                d='M148.621 17.7597L148.21 18.1775L148.621 18.5952L149.031 18.1775L148.621 17.7597Z'
                fill='#333333'
            />
            <path
                d='M149.685 17.3973L150.098 17.06L150.015 16.8914H149.437L149.355 17.06L149.685 17.3973Z'
                fill='#333333'
            />
            <path
                d='M147.128 21.5139L146.55 22.1042H147.293L147.458 21.9355L147.128 21.5139Z'
                fill='#333333'
            />
            <path
                d='M148.167 20.3788L147.465 21.093L147.874 21.5096L148.576 20.7954L148.167 20.3788Z'
                fill='#333333'
            />
            <path
                d='M149.239 19.3388L148.537 20.053L148.946 20.4696L149.648 19.7554L149.239 19.3388Z'
                fill='#333333'
            />
            <path
                d='M150.457 18.2384L149.755 18.9526L150.164 19.3692L150.866 18.655L150.457 18.2384Z'
                fill='#333333'
            />
            <path
                d='M151.525 17.0991L150.823 17.8125L151.232 18.2295L151.934 17.5161L151.525 17.0991Z'
                fill='#333333'
            />
            <path
                d='M152.411 16.8914H152.162L152.328 17.06L152.411 16.8914Z'
                fill='#333333'
            />
            <path
                d='M147.508 21.1184L147.098 21.5366L147.509 21.9539L147.919 21.5358L147.508 21.1184Z'
                fill='#333333'
            />
            <path
                d='M148.63 20.0376L148.219 20.4553L148.63 20.8731L149.04 20.4553L148.63 20.0376Z'
                fill='#333333'
            />
            <path
                d='M149.755 18.8373L149.344 19.2546L149.754 19.6728L150.165 19.2555L149.755 18.8373Z'
                fill='#333333'
            />
            <path
                d='M150.827 17.7973L150.416 18.2146L150.826 18.6328L151.237 18.2155L150.827 17.7973Z'
                fill='#333333'
            />
            <path
                d='M151.913 17.3973L152.326 17.06L152.16 16.8914H151.665L151.5 17.06L151.913 17.3973Z'
                fill='#333333'
            />
            <path
                d='M149.357 21.5139L148.779 22.1042H149.522L149.687 21.9355L149.357 21.5139Z'
                fill='#333333'
            />
            <path
                d='M154.639 16.8914H154.391L154.557 17.06L154.639 16.8914Z'
                fill='#333333'
            />
            <path
                d='M151.477 19.3305L150.775 20.0447L151.184 20.4613L151.886 19.7471L151.477 19.3305Z'
                fill='#333333'
            />
            <path
                d='M152.637 18.1852L151.936 18.9001L152.346 19.3163L153.047 18.6014L152.637 18.1852Z'
                fill='#333333'
            />
            <path
                d='M150.404 20.3725L149.702 21.0867L150.111 21.5033L150.813 20.7891L150.404 20.3725Z'
                fill='#333333'
            />
            <path
                d='M153.733 17.0853L153.032 17.8003L153.441 18.2165L154.142 17.5015L153.733 17.0853Z'
                fill='#333333'
            />
            <path
                d='M149.741 21.1082L149.331 21.5264L149.742 21.9437L150.152 21.5255L149.741 21.1082Z'
                fill='#333333'
            />
            <path
                d='M150.866 20.0293L150.456 20.447L150.866 20.8648L151.277 20.447L150.866 20.0293Z'
                fill='#333333'
            />
            <path
                d='M151.913 19.6638L152.326 19.3281L151.913 18.9084L151.5 19.3281L151.913 19.6638Z'
                fill='#333333'
            />
            <path
                d='M153.068 17.7914L152.657 18.2087L153.067 18.6269L153.478 18.2096L153.068 17.7914Z'
                fill='#333333'
            />
            <path
                d='M154.142 17.3973L154.555 17.06L154.39 16.8914H153.894L153.729 17.06L154.142 17.3973Z'
                fill='#333333'
            />
            <path
                d='M152.641 20.3647L151.939 21.0789L152.349 21.4955L153.05 20.7813L152.641 20.3647Z'
                fill='#333333'
            />
            <path
                d='M153.713 19.3241L153.012 20.0383L153.421 20.4549L154.123 19.7407L153.713 19.3241Z'
                fill='#333333'
            />
            <path
                d='M154.898 18.1643L154.193 18.8755L154.601 19.2938L155.306 18.5826L154.898 18.1643Z'
                fill='#333333'
            />
            <path
                d='M151.5 21.5139L151.005 22.1042H151.748L151.913 21.9355L151.5 21.5139Z'
                fill='#333333'
            />
            <path
                d='M156.869 16.8914H156.62L156.786 17.06L156.869 16.8914Z'
                fill='#333333'
            />
            <path
                d='M156.372 17.3957L155.96 17.0596L155.217 17.7319L155.629 18.152L156.372 17.3957Z'
                fill='#333333'
            />
            <path
                d='M151.5 21.513L151.913 21.9326L152.326 21.513L151.913 21.1772L151.5 21.513Z'
                fill='#333333'
            />
            <path
                d='M153.02 20.0212L152.609 20.439L153.02 20.8567L153.43 20.439L153.02 20.0212Z'
                fill='#333333'
            />
            <path
                d='M154.204 18.8145L153.794 19.2327L154.205 19.65L154.615 19.2318L154.204 18.8145Z'
                fill='#333333'
            />
            <path
                d='M155.217 18.5725L155.63 18.1524L155.217 17.7322L154.887 18.1524L155.217 18.5725Z'
                fill='#333333'
            />
            <path
                d='M156.372 17.3973L156.784 17.06L156.619 16.8914H156.041L155.959 17.06L156.372 17.3973Z'
                fill='#333333'
            />
            <path
                d='M155.96 19.3289L155.217 20.0011L155.63 20.4213L156.373 19.665L155.96 19.3289Z'
                fill='#333333'
            />
            <path
                d='M153.729 21.5139L153.233 22.1042H153.976L154.142 21.9355L153.729 21.5139Z'
                fill='#333333'
            />
            <path
                d='M154.82 20.3718L154.117 21.0845L154.526 21.502L155.229 20.7893L154.82 20.3718Z'
                fill='#333333'
            />
            <path
                d='M158.602 17.3957L158.189 17.0596L157.446 17.7319L157.859 18.152L158.602 17.3957Z'
                fill='#333333'
            />
            <path
                d='M157.053 18.2058L156.35 18.9185L156.759 19.3359L157.462 18.6232L157.053 18.2058Z'
                fill='#333333'
            />
            <path
                d='M159.097 16.8914H158.848L158.931 17.06L159.097 16.8914Z'
                fill='#333333'
            />
            <path
                d='M154.212 21.0894L153.802 21.5076L154.213 21.9249L154.623 21.5067L154.212 21.0894Z'
                fill='#333333'
            />
            <path
                d='M155.217 20.0022L154.887 20.4224L155.217 20.8426L155.63 20.4224L155.217 20.0022Z'
                fill='#333333'
            />
            <path
                d='M156.325 18.9019L155.915 19.3206L156.326 19.7374L156.736 19.3188L156.325 18.9019Z'
                fill='#333333'
            />
            <path
                d='M157.445 18.5725L157.858 18.1524L157.445 17.7322L157.115 18.1524L157.445 18.5725Z'
                fill='#333333'
            />
            <path
                d='M158.6 17.3973L158.931 17.06L158.848 16.8914H158.27L158.188 17.06L158.6 17.3973Z'
                fill='#333333'
            />
            <path
                d='M159.318 18.2333L158.616 18.9468L159.025 19.3638L159.727 18.6504L159.318 18.2333Z'
                fill='#333333'
            />
            <path
                d='M161.328 16.8914H161.079L161.162 17.06L161.328 16.8914Z'
                fill='#333333'
            />
            <path
                d='M155.96 21.5139L155.382 22.1042H156.208L156.373 21.9355L155.96 21.5139Z'
                fill='#333333'
            />
            <path
                d='M160.438 17.0566L159.737 17.7715L160.147 18.1877L160.847 17.4727L160.438 17.0566Z'
                fill='#333333'
            />
            <path
                d='M156.374 21.1765L156.787 21.5126L157.447 20.8403L157.117 20.4202L156.374 21.1765Z'
                fill='#333333'
            />
            <path
                d='M158.188 19.3075L157.486 20.0217L157.896 20.4383L158.597 19.7241L158.188 19.3075Z'
                fill='#333333'
            />
            <path
                d='M156.388 21.1599L155.979 21.5793L156.391 21.9953L156.8 21.5759L156.388 21.1599Z'
                fill='#333333'
            />
            <path
                d='M157.445 20.0022L157.115 20.4224L157.445 20.8426L157.858 20.4224L157.445 20.0022Z'
                fill='#333333'
            />
            <path
                d='M158.567 18.8725L158.156 19.2898L158.566 19.708L158.977 19.2907L158.567 18.8725Z'
                fill='#333333'
            />
            <path
                d='M159.7 17.7726L159.289 18.1899L159.699 18.6081L160.11 18.1908L159.7 17.7726Z'
                fill='#333333'
            />
            <path
                d='M160.83 17.3973L161.16 17.06L161.078 16.8914H160.5L160.417 17.06L160.83 17.3973Z'
                fill='#333333'
            />
            <path
                d='M158.19 21.5139L157.612 22.1042H158.438L158.603 21.9355L158.19 21.5139Z'
                fill='#333333'
            />
            <path
                d='M161.521 18.2077L160.82 18.9226L161.23 19.3388L161.931 18.6238L161.521 18.2077Z'
                fill='#333333'
            />
            <path
                d='M160.369 19.284L159.667 19.9978L160.077 20.4146L160.779 19.7008L160.369 19.284Z'
                fill='#333333'
            />
            <path
                d='M163.474 16.8914H163.308L163.391 17.06L163.474 16.8914Z'
                fill='#333333'
            />
            <path
                d='M162.632 16.9845L161.93 17.698L162.339 18.115L163.041 17.4016L162.632 16.9845Z'
                fill='#333333'
            />
            <path
                d='M158.604 21.1765L158.934 21.5126L159.677 20.8403L159.264 20.4202L158.604 21.1765Z'
                fill='#333333'
            />
            <path
                d='M158.537 21.1452L158.128 21.5647L158.54 21.9807L158.949 21.5612L158.537 21.1452Z'
                fill='#333333'
            />
            <path
                d='M159.756 20.031L159.346 20.4492L159.757 20.8665L160.167 20.4484L159.756 20.031Z'
                fill='#333333'
            />
            <path
                d='M160.804 18.8661L160.393 19.2834L160.803 19.7016L161.214 19.2843L160.804 18.8661Z'
                fill='#333333'
            />
            <path
                d='M161.981 17.7454L161.571 18.1636L161.982 18.5809L162.392 18.1627L161.981 17.7454Z'
                fill='#333333'
            />
            <path
                d='M162.977 17.3973L163.39 17.06L163.308 16.8914H162.73L162.647 17.06L162.977 17.3973Z'
                fill='#333333'
            />
            <path
                d='M161.446 20.3932L160.744 21.1074L161.153 21.524L161.855 20.8098L161.446 20.3932Z'
                fill='#333333'
            />
            <path
                d='M165.704 16.8914H165.537L165.621 17.06L165.704 16.8914Z'
                fill='#333333'
            />
            <path
                d='M163.712 18.2216L163.01 18.9351L163.419 19.3521L164.121 18.6387L163.712 18.2216Z'
                fill='#333333'
            />
            <path
                d='M160.419 21.5139L159.841 22.1042H160.667L160.832 21.9355L160.419 21.5139Z'
                fill='#333333'
            />
            <path
                d='M164.767 17.0976L164.066 17.8125L164.476 18.2287L165.177 17.5138L164.767 17.0976Z'
                fill='#333333'
            />
            <path
                d='M162.664 19.2939L161.962 20.0081L162.372 20.4247L163.073 19.7105L162.664 19.2939Z'
                fill='#333333'
            />
            <path
                d='M160.779 21.1223L160.369 21.5405L160.78 21.9578L161.19 21.5397L160.779 21.1223Z'
                fill='#333333'
            />
            <path
                d='M161.945 19.9596L161.534 20.377L161.944 20.7951L162.355 20.3778L161.945 19.9596Z'
                fill='#333333'
            />
            <path
                d='M163.022 18.8297L162.61 19.2466L163.02 19.6652L163.431 19.2483L163.022 18.8297Z'
                fill='#333333'
            />
            <path
                d='M164.132 18.5725L164.545 18.1524L164.132 17.7322L163.719 18.1524L164.132 18.5725Z'
                fill='#333333'
            />
            <path
                d='M165.207 17.3973L165.62 17.06L165.537 16.8914H164.959L164.877 17.06L165.207 17.3973Z'
                fill='#333333'
            />
            <path
                d='M162.649 21.5139L162.071 22.1042H162.814L162.979 21.9355L162.649 21.5139Z'
                fill='#333333'
            />
            <path
                d='M167.931 16.8914H167.683L167.848 17.06L167.931 16.8914Z'
                fill='#333333'
            />
            <path
                d='M163.706 20.413L163.005 21.1279L163.415 21.5441L164.116 20.8291L163.706 20.413Z'
                fill='#333333'
            />
            <path
                d='M164.84 19.3449L164.138 20.0591L164.547 20.4757L165.249 19.7615L164.84 19.3449Z'
                fill='#333333'
            />
            <path
                d='M166.362 18.5718L165.95 18.1516L165.207 18.9079L165.619 19.3281L166.362 18.5718Z'
                fill='#333333'
            />
            <path
                d='M167.437 17.3957L167.024 17.0596L166.364 17.7319L166.694 18.152L167.437 17.3957Z'
                fill='#333333'
            />
            <path
                d='M162.989 21.1677L162.578 21.5854L162.989 22.0032L163.399 21.5855L162.989 21.1677Z'
                fill='#333333'
            />
            <path
                d='M164.165 20.0713L163.755 20.4895L164.166 20.9068L164.576 20.4886L164.165 20.0713Z'
                fill='#333333'
            />
            <path
                d='M165.207 19.6638L165.62 19.3281L165.207 18.9084L164.876 19.3281L165.207 19.6638Z'
                fill='#333333'
            />
            <path
                d='M166.326 17.8165L165.917 18.2351L166.328 18.652L166.737 18.2334L166.326 17.8165Z'
                fill='#333333'
            />
            <path
                d='M167.437 17.3973L167.85 17.06L167.685 16.8914H167.19L167.025 17.06L167.437 17.3973Z'
                fill='#333333'
            />
            <path
                d='M169.667 17.3957L169.254 17.0596L168.593 17.7319L168.923 18.152L169.667 17.3957Z'
                fill='#333333'
            />
            <path
                d='M164.878 21.5139L164.3 22.1042H165.043L165.208 21.9355L164.878 21.5139Z'
                fill='#333333'
            />
            <path
                d='M170.16 16.8914H169.911L170.077 17.06L170.16 16.8914Z'
                fill='#333333'
            />
            <path
                d='M168.592 18.5718L168.179 18.1516L167.436 18.9079L167.849 19.3281L168.592 18.5718Z'
                fill='#333333'
            />
            <path
                d='M167.024 19.3289L166.364 20.0011L166.694 20.4213L167.437 19.665L167.024 19.3289Z'
                fill='#333333'
            />
            <path
                d='M165.879 20.4094L165.176 21.1221L165.584 21.5396L166.287 20.8269L165.879 20.4094Z'
                fill='#333333'
            />
            <path
                d='M164.876 21.513L165.207 21.9326L165.62 21.513L165.207 21.1772L164.876 21.513Z'
                fill='#333333'
            />
            <path
                d='M166.34 20.0646L165.929 20.4814L166.339 20.9001L166.75 20.4832L166.34 20.0646Z'
                fill='#333333'
            />
            <path
                d='M167.437 19.6638L167.85 19.3281L167.437 18.9084L167.024 19.3281L167.437 19.6638Z'
                fill='#333333'
            />
            <path
                d='M168.591 18.5725L168.921 18.1524L168.591 17.7322L168.178 18.1524L168.591 18.5725Z'
                fill='#333333'
            />
            <path
                d='M169.668 17.3973L170.081 17.06L169.915 16.8914H169.42L169.255 17.06L169.668 17.3973Z'
                fill='#333333'
            />
            <path
                d='M171.46 17.0451L170.759 17.7593L171.168 18.1759L171.87 17.4617L171.46 17.0451Z'
                fill='#333333'
            />
            <path
                d='M170.443 18.1447L169.742 18.8589L170.151 19.2755L170.853 18.5613L170.443 18.1447Z'
                fill='#333333'
            />
            <path
                d='M167.436 21.1765L167.849 21.5126L168.592 20.8403L168.179 20.4202L167.436 21.1765Z'
                fill='#333333'
            />
            <path
                d='M167.024 21.5139L166.529 22.1042H167.272L167.437 21.9355L167.024 21.5139Z'
                fill='#333333'
            />
            <path
                d='M172.39 16.8914H172.141L172.307 17.06L172.39 16.8914Z'
                fill='#333333'
            />
            <path
                d='M169.254 19.3289L168.593 20.0011L168.923 20.4213L169.667 19.665L169.254 19.3289Z'
                fill='#333333'
            />
            <path
                d='M167.024 21.513L167.437 21.9326L167.85 21.513L167.437 21.1772L167.024 21.513Z'
                fill='#333333'
            />
            <path
                d='M168.562 20.0369L168.15 20.4534L168.559 20.8724L168.971 20.456L168.562 20.0369Z'
                fill='#333333'
            />
            <path
                d='M169.668 19.6638L170.081 19.3281L169.668 18.9084L169.255 19.3281L169.668 19.6638Z'
                fill='#333333'
            />
            <path
                d='M170.765 17.7422L170.354 18.1599L170.765 18.5777L171.175 18.1599L170.765 17.7422Z'
                fill='#333333'
            />
            <path
                d='M171.896 17.3973L172.309 17.06L172.144 16.8914H171.566L171.483 17.06L171.896 17.3973Z'
                fill='#333333'
            />
            <path
                d='M169.254 21.5139L168.758 22.1042H169.501L169.667 21.9355L169.254 21.5139Z'
                fill='#333333'
            />
            <path
                d='M169.666 21.1765L170.078 21.5126L170.739 20.8403L170.409 20.4202L169.666 21.1765Z'
                fill='#333333'
            />
            <path
                d='M174.124 17.3957L173.711 17.0596L172.968 17.7319L173.381 18.152L174.124 17.3957Z'
                fill='#333333'
            />
            <path
                d='M171.481 19.3289L170.738 20.0011L171.151 20.4213L171.894 19.665L171.481 19.3289Z'
                fill='#333333'
            />
            <path
                d='M172.556 18.2201L171.855 18.9351L172.265 19.3512L172.966 18.6363L172.556 18.2201Z'
                fill='#333333'
            />
            <path
                d='M169.255 21.513L169.668 21.9326L170.081 21.513L169.668 21.1772L169.255 21.513Z'
                fill='#333333'
            />
            <path
                d='M170.789 20.0435L170.379 20.4617L170.79 20.879L171.2 20.4608L170.789 20.0435Z'
                fill='#333333'
            />
            <path
                d='M171.896 19.6638L172.309 19.3281L171.896 18.9084L171.483 19.3281L171.896 19.6638Z'
                fill='#333333'
            />
            <path
                d='M173.014 17.7571L172.604 18.1753L173.015 18.5926L173.425 18.1744L173.014 17.7571Z'
                fill='#333333'
            />
            <path
                d='M174.122 17.3973L174.452 17.06C174.452 17.06 174.039 16.8914 173.792 16.8914H173.709L174.122 17.3973Z'
                fill='#333333'
            />
            <path
                d='M175.613 18.1558L175.778 17.9867C175.778 17.9867 175.53 17.6487 175.447 17.5642L175.282 17.7332L175.695 18.1558H175.613Z'
                fill='#333333'
            />
            <path
                d='M174.455 19.3281L175.198 18.5718L174.785 18.1516L174.125 18.9079L174.455 19.3281Z'
                fill='#333333'
            />
            <path
                d='M173.69 19.2924L172.987 20.0059L173.396 20.4229L174.099 19.7095L173.69 19.2924Z'
                fill='#333333'
            />
            <path
                d='M172.573 20.4137L171.871 21.1279L172.28 21.5445L172.982 20.8303L172.573 20.4137Z'
                fill='#333333'
            />
            <path
                d='M171.481 21.5139L170.903 22.1042H171.729L171.894 21.9355L171.481 21.5139Z'
                fill='#333333'
            />
            <path
                d='M171.893 21.1345L171.483 21.5527L171.894 21.97L172.304 21.5518L171.893 21.1345Z'
                fill='#333333'
            />
            <path
                d='M172.995 19.9906L172.584 20.408L172.994 20.8261L173.405 20.4088L172.995 19.9906Z'
                fill='#333333'
            />
            <path
                d='M174.122 19.6638L174.453 19.3281L174.122 18.9084L173.709 19.3281L174.122 19.6638Z'
                fill='#333333'
            />
            <path
                d='M175.611 18.1524L175.198 17.7322L174.786 18.1524L175.198 18.5725L175.611 18.1524Z'
                fill='#333333'
            />
            <path
                d='M175.197 20.0011L175.61 20.4213L176.27 19.749L175.94 19.3289L175.197 20.0852V20.0011Z'
                fill='#333333'
            />
            <path
                d='M174.125 21.1765L174.455 21.5126L175.198 20.8403L174.785 20.4202L174.125 21.1765Z'
                fill='#333333'
            />
            <path
                d='M173.711 21.5139L173.133 22.1042H173.711C173.711 22.1042 173.876 22.1042 173.958 22.1042H174.041L173.628 21.5982L173.711 21.5139Z'
                fill='#333333'
            />
            <path
                d='M173.709 21.513L174.122 21.9326L174.453 21.513L174.122 21.1772L173.709 21.513Z'
                fill='#333333'
            />
            <path
                d='M175.232 19.9843L174.821 20.4016L175.231 20.8198L175.642 20.4025L175.232 19.9843Z'
                fill='#333333'
            />
            <path
                d='M176.269 19.6634C176.269 19.6634 176.269 19.5794 176.269 19.4955C176.269 19.3277 176.269 19.1598 176.187 18.9919L175.939 19.2437L176.269 19.5794V19.6634Z'
                fill='#333333'
            />
        </g>
        <g opacity='0.3'>
            <path
                d='M205.585 20.0008C205.585 22.6899 203.438 24.8749 200.796 24.8749C198.154 24.8749 196.007 22.6899 196.007 20.0008C196.007 17.3116 198.154 15.1267 200.796 15.1267C203.438 15.1267 205.585 17.3116 205.585 20.0008Z'
                fill='url(#paint11_radial_3188_12191)'
            />
            <path
                d='M202.776 16.5547C200.299 16.7228 199.061 19.0758 201.455 22.8574C204.097 22.6053 205.171 20.1682 202.776 16.5547Z'
                fill='url(#paint12_linear_3188_12191)'
            />
        </g>
        <path
            d='M297.309 568.167H302.18V564.47H297.309V568.167ZM297.309 59.3298V63.0274H302.18V59.3298H297.309Z'
            fill='url(#paint13_linear_3188_12191)'
        />
        <path
            d='M6.85512 564.47H1.98389V568.167H6.85512V564.47ZM1.98389 59.3298V63.0274H6.85512V59.3298H1.98389Z'
            fill='url(#paint14_linear_3188_12191)'
        />
        <path
            d='M243.728 623.042V628H247.361V623.042H243.728ZM54.8232 628H58.456V623.042H54.8232V628Z'
            fill='url(#paint15_linear_3188_12191)'
        />
        <path
            d='M243.728 0V4.95812H247.361V0H243.728ZM54.8232 4.95812H58.456V0H54.8232V4.95812Z'
            fill='url(#paint16_linear_3188_12191)'
        />
        <path
            d='M303.834 160.339C303.173 160.927 303.173 162.86 303.173 166.474V227.652C303.173 228.828 303.173 230.173 303.751 230.341C303.999 230.173 303.999 229.501 303.999 228.744V162.944C303.999 162.02 303.999 160.675 303.751 160.507L303.834 160.339Z'
            fill='url(#paint17_linear_3188_12191)'
        />
        <path
            d='M303.256 227.653V166.475C303.256 162.945 303.256 161.012 303.917 160.34L302.183 159.248C301.275 160.592 301.357 163.533 301.357 164.626V226.056C301.357 228.829 301.523 230.678 302.018 231.098L303.834 230.342C303.256 230.174 303.256 228.829 303.256 227.653Z'
            fill='url(#paint18_linear_3188_12191)'
        />
        <path
            d='M0.330626 148.827C0.991133 149.415 0.991148 151.348 0.991148 154.962V190.005C0.991148 191.181 0.991151 192.526 0.413207 192.694C0.165517 192.526 0.165527 191.854 0.165527 191.097V151.432C0.165527 150.508 0.165517 149.163 0.413207 148.995L0.330626 148.827Z'
            fill='url(#paint19_linear_3188_12191)'
        />
        <path
            d='M0.991332 189.922V154.879C0.991332 151.349 0.991317 149.416 0.330811 148.744L2.06466 147.652C2.97286 148.996 2.89029 151.937 2.89029 153.03V188.325C2.89029 191.098 2.72517 192.947 2.22979 193.367L0.413392 192.611C0.991335 192.443 0.991332 191.098 0.991332 189.922Z'
            fill='url(#paint20_linear_3188_12191)'
        />
        <path
            d='M0.330626 205.215C0.991133 205.804 0.991148 207.736 0.991148 211.35V246.393C0.991148 247.569 0.991151 248.914 0.413207 249.082C0.165517 248.914 0.165527 248.242 0.165527 247.485V207.82C0.165527 206.896 0.165517 205.551 0.413207 205.383L0.330626 205.215Z'
            fill='url(#paint21_linear_3188_12191)'
        />
        <path
            d='M0.991332 246.31V211.267C0.991332 207.737 0.991317 205.804 0.330811 205.132L2.06466 204.04C2.97286 205.384 2.89029 208.325 2.89029 209.418V244.713C2.89029 247.486 2.72517 249.335 2.22979 249.755L0.413392 248.999C0.991335 248.831 0.991332 247.486 0.991332 246.31Z'
            fill='url(#paint22_linear_3188_12191)'
        />
        <path
            d='M0 101.347V123.449L0.577941 123.953V100.759L0 101.347Z'
            fill='#555658'
        />
        <path
            d='M2.06531 100.759H0.496582V123.953H2.14785C2.14785 123.953 2.39553 123.28 2.39553 122.692V101.935C2.39553 101.263 2.39554 100.843 1.98272 100.675L2.06531 100.759Z'
            fill='url(#paint23_linear_3188_12191)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_3188_12191'
                x1='275.847'
                y1='7.05775'
                x2='298.464'
                y2='21.8989'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint1_linear_3188_12191'
                x1='279.643'
                y1='160.34'
                x2='301.026'
                y2='160.34'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint2_linear_3188_12191'
                x1='6.93747'
                y1='21.9321'
                x2='29.8871'
                y2='6.92782'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint3_linear_3188_12191'
                x1='25.0182'
                y1='159.92'
                x2='3.63423'
                y2='159.92'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint4_linear_3188_12191'
                x1='152.329'
                y1='10.924'
                x2='152.329'
                y2='1.00776'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#BFC1C7' />
                <stop offset='0.35' stopColor='#C1C3C9' />
                <stop offset='0.57' stopColor='#CACCD1' />
                <stop offset='0.75' stopColor='#D9DADF' />
                <stop offset='0.92' stopColor='#EDEEF2' />
                <stop offset='1' stopColor='#FBFCFF' />
            </linearGradient>
            <linearGradient
                id='paint5_linear_3188_12191'
                x1='275.762'
                y1='620.943'
                x2='298.299'
                y2='606.018'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint6_linear_3188_12191'
                x1='279.808'
                y1='462.368'
                x2='300.944'
                y2='462.368'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint7_linear_3188_12191'
                x1='29.2285'
                y1='621.111'
                x2='7.77727'
                y2='606.924'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint8_linear_3188_12191'
                x1='24.853'
                y1='461.863'
                x2='3.55167'
                y2='461.863'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint9_linear_3188_12191'
                x1='152.329'
                y1='617.078'
                x2='152.329'
                y2='626.994'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#BFC1C7' />
                <stop offset='0.35' stopColor='#C1C3C9' />
                <stop offset='0.57' stopColor='#CACCD1' />
                <stop offset='0.75' stopColor='#D9DADF' />
                <stop offset='0.92' stopColor='#EDEEF2' />
                <stop offset='1' stopColor='#FBFCFF' />
            </linearGradient>
            <linearGradient
                id='paint10_linear_3188_12191'
                x1='7.9248'
                y1='313.789'
                x2='296.731'
                y2='313.789'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#2E2E2E' />
                <stop offset='0.07' />
                <stop offset='0.18' stopColor='#363636' />
                <stop offset='0.8' stopColor='#363636' />
                <stop offset='0.94' />
                <stop offset='1' stopColor='#2E2E2E' />
            </linearGradient>
            <radialGradient
                id='paint11_radial_3188_12191'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(190.266 24.6597) scale(4.74407 4.82871)'
            >
                <stop offset='0.12' />
                <stop offset='0.3' stopColor='#050505' />
                <stop offset='0.51' stopColor='#141414' />
                <stop offset='0.74' stopColor='#2C2C2C' />
                <stop offset='0.98' stopColor='#4E4E4E' />
                <stop offset='0.99' stopColor='#515151' />
            </radialGradient>
            <linearGradient
                id='paint12_linear_3188_12191'
                x1='200.465'
                y1='17.5631'
                x2='204.766'
                y2='22.783'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#828282' />
                <stop offset='0.12' stopColor='#646464' />
                <stop offset='0.3' stopColor='#404040' />
                <stop offset='0.47' stopColor='#242424' />
                <stop offset='0.65' stopColor='#101010' />
                <stop offset='0.82' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint13_linear_3188_12191'
                x1='297.309'
                y1='313.707'
                x2='302.18'
                y2='313.707'
                gradientUnits='userSpaceOnUse'
            >
                <stop />
                <stop offset='0.36' stopColor='#040404' />
                <stop offset='0.72' stopColor='#101010' />
                <stop offset='1' stopColor='#1F1F1F' />
            </linearGradient>
            <linearGradient
                id='paint14_linear_3188_12191'
                x1='2.06645'
                y1='313.707'
                x2='6.85512'
                y2='313.707'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1F1F1F' />
                <stop offset='0.28' stopColor='#101010' />
                <stop offset='0.64' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint15_linear_3188_12191'
                x1='151.092'
                y1='628'
                x2='151.092'
                y2='623.042'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1F1F1F' />
                <stop offset='0.28' stopColor='#101010' />
                <stop offset='0.64' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint16_linear_3188_12191'
                x1='151.092'
                y1='4.95812'
                x2='151.092'
                y2='0'
                gradientUnits='userSpaceOnUse'
            >
                <stop />
                <stop offset='0.36' stopColor='#040404' />
                <stop offset='0.72' stopColor='#101010' />
                <stop offset='1' stopColor='#1F1F1F' />
            </linearGradient>
            <linearGradient
                id='paint17_linear_3188_12191'
                x1='303.256'
                y1='195.298'
                x2='304.082'
                y2='195.298'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.17' stopColor='#4D4D4D' />
                <stop offset='0.33' stopColor='#595959' />
                <stop offset='0.48' stopColor='#6C6C6C' />
                <stop offset='0.64' stopColor='#878787' />
                <stop offset='0.79' stopColor='#AAAAAA' />
                <stop offset='0.94' stopColor='#D4D4D4' />
                <stop offset='1' stopColor='#E6E6E6' />
            </linearGradient>
            <linearGradient
                id='paint18_linear_3188_12191'
                x1='302.596'
                y1='159.079'
                x2='302.596'
                y2='231.182'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint19_linear_3188_12191'
                x1='0.991148'
                y1='170.676'
                x2='0.0829653'
                y2='170.676'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.29' stopColor='#4D4D4D' />
                <stop offset='0.56' stopColor='#595959' />
                <stop offset='0.83' stopColor='#6C6C6C' />
                <stop offset='1' stopColor='#7D7D7D' />
            </linearGradient>
            <linearGradient
                id='paint20_linear_3188_12191'
                x1='1.65183'
                y1='147.568'
                x2='1.65183'
                y2='193.451'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint21_linear_3188_12191'
                x1='0.991148'
                y1='227.065'
                x2='0.0829653'
                y2='227.065'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.4' stopColor='#4D4D4D' />
                <stop offset='0.79' stopColor='#595959' />
                <stop offset='1' stopColor='#636363' />
            </linearGradient>
            <linearGradient
                id='paint22_linear_3188_12191'
                x1='1.65183'
                y1='203.956'
                x2='1.65183'
                y2='249.839'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint23_linear_3188_12191'
                x1='1.48734'
                y1='100.759'
                x2='1.48734'
                y2='124.037'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
        </defs>
    </svg>
)

export default PhoneBig
