import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { PAYMENT_URLS } from 'config/urls'

import {
    ICustomer,
    ISubscription,
    IPaymentLink,
    TInvoice,
    ICreditPack,
} from '../store/types'

export const getCustomer = (
    lang?: string | undefined,
): Promise<AxiosResponse<ICustomer>> => {
    const queryParam = lang ? `?language=${lang}` : ''
    return axiosInstance.get(`${PAYMENT_URLS.main}/customer${queryParam}`)
}
export const editCustomer = ({
    customer,
}: {
    customer: ICustomer
}): Promise<AxiosResponse<ICustomer>> =>
    axiosInstance.post(`${PAYMENT_URLS.main}/customer`, customer)

export const getSubscriptionTypes = (
    lang?: string | undefined,
): Promise<AxiosResponse<ISubscription>> => {
    const queryParam = lang ? `?language=${lang}` : ''
    return axiosInstance.get(
        `${PAYMENT_URLS.main}/subscriptiontypes${queryParam}`,
    )
}

export const getPointPacks = (
    lang?: string | undefined,
): Promise<AxiosResponse<ICreditPack>> => {
    const queryParam = lang ? `?language=${lang}` : ''
    return axiosInstance.get(`${PAYMENT_URLS.credits}${queryParam}`)
}

export const unsubscribe = ({
    subscriptionId,
}: {
    subscriptionId: string
}): Promise<AxiosResponse<ICustomer>> =>
    axiosInstance.post(`${PAYMENT_URLS.main}/unsubscribe`, { subscriptionId })

export const getInvoices = (): Promise<AxiosResponse<TInvoice[]>> =>
    axiosInstance.get(`${PAYMENT_URLS.main}/get-customer-invoices`)

export const getPaymentLink = ({
    subscriptionId,
}: {
    subscriptionId: string
}): Promise<AxiosResponse<IPaymentLink>> =>
    axiosInstance.get(
        `${PAYMENT_URLS.main}/${subscriptionId}/create-payment-link`,
    )

export const getPointsPaymentLink = ({
    packId,
}: {
    packId: string
}): Promise<AxiosResponse<IPaymentLink>> =>
    axiosInstance.get(
        `${PAYMENT_URLS.main}/${packId}/create-payment-link-for-points`,
    )

export const trialSubscription = ({
    subscriptionId,
}: {
    subscriptionId: string
}): Promise<AxiosResponse<ICustomer>> =>
    axiosInstance.post(
        `${PAYMENT_URLS.main}/subscribe?subscriptionId=${subscriptionId}`,
    )

export const promocodeApply = ({
    promocode,
    subscriptionId,
}: {
    promocode: string
    subscriptionId: string
}): Promise<AxiosResponse> =>
    /* eslint-disable max-len */
    axiosInstance.post(
        `${PAYMENT_URLS.promocode}?promotionCode=${promocode}&subscriptionId=${subscriptionId}`,
    )

export const fetchPaymentLink = ({
    placementId,
    email,
}: {
    placementId: string
    email: string
}) =>
    axiosInstance.get(
        `${PAYMENT_URLS.main}/${placementId}/create-payment-link-for-placements?email=${email}`,
    )

export const fetchPlacementTypes = () =>
    axiosInstance.get(`${PAYMENT_URLS.main}/placement-types`)
