import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
// import { IPagination } from 'config/types'
import { testTypes } from './actionTypes'
import {
    getTests,
    getTestById,
    createTest,
    editTest,
    deleteTest,
} from '../service/tests'

import {
    ITest,
    FetchTestsRequest,
    FetchTestByIdRequest,
    CreateTestRequest,
    EditTestRequest,
    DeleteTestRequest,
    IQuestions,
} from './types'

function* fetchTestsSaga(action: FetchTestsRequest) {
    try {
        const response: AxiosResponse<IQuestions[]> = yield call(
            getTests,
            action.payload,
        )
        if (response.data) {
            const updTests = response.data.map((test, index) => ({
                ...test,
                index,
            }))

            console.log(updTests, 'updTests')

            yield put({
                type: testTypes.SET_TESTS,
                payload: { tests: updTests },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchTestByIdSaga(action: FetchTestByIdRequest) {
    try {
        const response: AxiosResponse<ITest> = yield call(
            getTestById,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: testTypes.SET_TEST,
                payload: { test: response.data },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createTestSaga(action: CreateTestRequest) {
    try {
        console.log(action.payload, 'payload')

        const response: AxiosResponse<ITest[]> = yield call(
            createTest,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editTestSaga(action: EditTestRequest) {
    try {
        const response: AxiosResponse<ITest> = yield call(
            editTest,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteTestSaga(action: DeleteTestRequest) {
    try {
        const response: AxiosResponse<ITest> = yield call(
            deleteTest,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { tests } = yield select(state => state.survey)
            const updatedTests = tests.filter(
                (test: ITest) => test.id !== action.payload.testId,
            )

            yield put({
                type: testTypes.SET_TESTS,
                payload: { tests: updatedTests },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* surveySaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([takeLatest(testTypes.FETCH_TESTS_REQUEST, fetchTestsSaga)])
    yield all([takeLatest(testTypes.FETCH_TEST_BY_ID, fetchTestByIdSaga)])
    yield all([takeLatest(testTypes.CREATE_TEST_REQUEST, createTestSaga)])
    yield all([takeLatest(testTypes.EDIT_TEST_REQUEST, editTestSaga)])
    yield all([takeLatest(testTypes.DELETE_TEST_REQUEST, deleteTestSaga)])
}

export default surveySaga
